.accordion-button::after {
    transform: rotate(-90deg) !important;
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) !important;
}

.accordion-item {
    background: transparent !important;
    border: none;

    button,
    div,
    .accordion-header {
        background: transparent;
    }

    .accordion-button {
        font-weight: 700;
        color: $text-color3;
        font-size: 1.25rem;
        padding-left: 0 !important;
        padding-right: 0 !important;

        &:focus {
            box-shadow: none;
        }
    }

    .accordion-collapse {
        p {
            color: $text-color3;
        }
    }
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}
