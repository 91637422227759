body {
    margin: 0;
    // font-family: "Nunito Sans", sans-serif;
    // font-family: "Roboto Condensed", sans-serif;
    font-family: "Helvetica" !important;
    background-color: $bg1;
    overflow-x: hidden !important;
}

@media (max-width: 1000px) {
    #root {
        width: 100vw;
        overflow: hidden;
    }
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("../../fonts/Roboto Condensed/RobotoCondensed-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("../../fonts/Roboto Condensed/RobotoCondensed-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Roboto Condensed";
    src: url("../../fonts/Roboto Condensed/RobotoCondensed-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: "Helvetica";
    src: url("../../fonts/Helvetica/HelveticaNowText-Bold.otf");
    font-weight: 700;
}

@font-face {
    font-family: "Helvetica";
    src: url("../../fonts/Helvetica/HelveticaNowText-Medium.otf");
    font-weight: 500;
}

@font-face {
    font-family: "Helvetica";
    src: url("../../fonts/Helvetica/HelveticaNowText-Regular.otf");
    font-weight: 400;
}

@font-face {
    font-family: "Helvetica";
    src: url("../../fonts/Helvetica/HelveticaNowText-RegIta.otf");
    font-weight: 400;
    font-style: italic;
}

a {
    text-decoration: none !important;
}

.block-bg1 {
    background-color: $bg1;
}

.block-bg2 {
    background-color: $bg2;
}

.block-bg3 {
    background-color: $text-color5;
}

.block-bg4 {
    background-color: $bg4;
}

.bg-highlight {
    background-color: $highlight;
}

.block-bg-white {
    background-color: white;
}

.small-heading {
    color: $text-color3;
}

.heading {
    font-family: "Roboto Condensed";
}

.h-0 {
    height: 0 !important;
}

.px-60 {
    padding-left: 60px;
    padding-right: 60px;
}

.pt-60 {
    padding-top: 60px;
}

.pt-80 {
    padding-top: 80px;
}

.pb-80 {
    padding-bottom: 80px;
}

.pt-120 {
    padding-top: 120px;
}

.pb-120 {
    padding-bottom: 120px;
}

.px-120 {
    padding-left: 120px;
    padding-right: 120px;
}

.w-80 {
    width: 80% !important;
}

.pagination {
    .btn-outline-primary {
        background: rgba(34, 58, 94, 1) !important;
        border: none !important;
        color: white !important;
        opacity: 1 !important;
        margin: 0 0.3rem;

        &.active {
            background: #f89336 !important;
        }
    }
}

.swiper-button-next-n,
.swiper-button-prev-n {
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.limit-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
    min-height: 4.125rem;
}

@media (min-width: 1080px) {
    .pt-md-60 {
        padding-top: 60px !important;
    }

    .pt-md-80 {
        padding-top: 80px !important;
    }

    .pb-md-80 {
        padding-bottom: 80px !important;
    }

    .py-md-60 {
        padding-top: 60px !important;
        padding-bottom: 60px !important;
    }

    .px-md-180 {
        padding-left: 180px !important;
        padding-right: 180px !important;
    }

    .px-md-120 {
        padding-left: 120px !important;
        padding-right: 120px !important;
    }

    .px-md-60 {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }

    .mt-md-90 {
        margin-top: 90px;
    }

    .mt-md-120 {
        margin-top: 120px;
    }
}

.t-color1 {
    color: $text-color1;
}

.t-color2 {
    color: $text-color2;
}

.t-color3 {
    color: $text-color3;
}

.t-color4 {
    color: $text-color4 !important;
}

.t-color5 {
    color: $text-color5;
}

.t-color6 {
    color: $text-color6;
}

.t-black {
    color: black !important;
}

.highlight {
    color: $highlight;
}

.text-gradient {
    background: linear-gradient(to bottom, #4e525a 0%, rgba(78, 82, 90, 0.7) 50%, rgba(78, 82, 90, 0.4) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-justify {
    text-align: justify;
}

@media (min-width: 1200px) {
    .w-md-75 {
        width: 75% !important;
    }

    .h-md-75 {
        height: 75% !important;
    }
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-500 {
    font-weight: 500;
}

.section-heading {
    font-weight: 500;
    line-height: 2.375rem;
    font-size: 2rem;
    color: $text-color3;
    font-family: "Roboto Condensed", sans-serif !important;
}

// background: linear-gradient(90deg, #8299B9 0%, #445C7E 28.13%, #334E76 47.4%, #445C7E 69.79%, #8299B9 100%);

.card {
    background: #ffffff;
    border: 1px solid $text-color6;
    border-radius: 16px;
    padding: 33px;
}

.hover-img {
    transition: all 0.5s;

    &:hover {
        transform: scale(1.03) !important;
    }
}

.hover-text-shift {
    transition: all 0.5s;

    &:hover {
        margin-left: 1rem !important;
    }
}

.hover-text-underline {
    transition: all 0.5s;

    &:hover {
        // border-bottom: 1px solid;
        text-decoration: underline !important;
    }
}

.announcements-item {
    .label {
        position: absolute;
        top: -30px;
        font-size: 1rem;
        line-height: 19px;
        background: $highlight !important;
        border-radius: 8px;
        padding: 3px 27px;
        color: white;
    }
}

@media (max-width: 1000px) {
    .d-mob-none {
        display: none;
    }
}

@media (max-width: 800px) {
    body .uwy.userway_p1 .uai {
        top: 95px !important;
    }
}

.fs-5_5 {
    font-size: 1.125rem !important;
}

.cursor {
    cursor: pointer;
}

.text-underline {
    text-decoration: underline !important;
}

.apt-search-modal-table {
    & > tbody > tr:hover > * {
        color: white !important;
        background-color: red;
    }
}

// sliderrr
.swiper-button-prev-n,
.swiper-button-next-n {
    position: absolute;
    top: 60%;
    cursor: pointer;
}

.swiper-button-prev-n {
    left: 0;
    transform: translate(-150%, -245%);
}

.swiper-button-next-n {
    right: 0;
    transform: translate(175%, -245%);
}

.swiper-button-prev-n.swiper-button-disabled,
.swiper-button-next-n.swiper-button-disabled {
    opacity: 0.35;
}

/// Width
@media (min-width: 500px) {
    .w-md-31 {
        width: 31% !important;
    }
}

@media (max-width: 768px) {
    .w-sm-100 {
        width: 100% !important;
    }
}

@media (max-width: 900px) {
    .w-sm-50 {
        width: 50% !important;
    }
}

.w-12 {
    width: 12% !important;
}

// loader
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: $text-color3;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
