.sceneViewer {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.overlay {
  opacity: 0;
  will-change: opacity;
  transition: opacity 1s ease;
}

.overlayActive {
  opacity: 1;
}

.svg {
  height: 100%;
  width: 100%;
  position: relative;
}
