.constitution-page {
    .card {
        p {
            //font-weight: 600;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: $text-color3;
        }

        small {
            //font-weight: 600;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: $text-color5;
        }
    }

    table {
        border-collapse: separate;
        border-spacing: 0 1rem;

        thead {
            background-color: #8ca2c2;
            color: white;

            th {
                padding: 24px 0;
                font-weight: 500;
                font-size: 24px;
                line-height: 29px;
                vertical-align: middle;
                text-align: center;
            }
        }

        tbody {
            tr {
                background-color: white;
            }

            td {
                text-align: center;
                vertical-align: middle;
                padding: 24px 0;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: $text-color3 !important;

                a {
                    // border-bottom: 1px solid;
                    text-decoration: underline;
                    color: $text-color3 !important;
                    vertical-align: middle;
                }
            }
        }
    }
}

@media (max-width: 1080px) {
    .constitution-page {
        table {
            thead {
                th {
                    padding: 20px 0;
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            tbody {
                td {
                    padding: 20px 0;
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .constitution-page {
        table {
            thead {
                th {
                    padding: 18px 0;
                    font-size: 0.9rem;
                    line-height: 22px;
                }
            }

            tbody {
                td {
                    padding: 18px 3px;
                    font-size: 0.6rem;
                    line-height: 20px;
                }
            }

            img{
                width: 15px;
                height: 15px;
            }
        }
    }
}
