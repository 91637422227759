.image-holder {
    color: $text-color3;
    text-align: start;

    h6 {
        font-size: 18px;
        line-height: 22px;
    }

    small {
        font-size: 16px;
        line-height: 19px;
        font-style: italic;
    }
}

.text-inf {
    text-align: justify;
    margin-bottom: 0;
    line-height: 36px;
    color: $text-color1;
    font-size: 18px;
}

.text-inf {
    p {
        text-align: justify;
        margin-bottom: 0;
        color: $text-color1;
        line-height: 36px;
        // padding-top: 1.2rem;
        // padding-bottom: 1.2rem;
    }
}

// .audit-card {
//     flex: initial;
//     position: relative;
//     // height: 52rem;
//     // width: 48rem;
//     -moz-perspective: 200rem;
//     perspective: 200rem;
//     margin: 2rem;

//     &__side {
//         // height: 52rem;
//         transition: all 0.8s ease;
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         -webkit-backface-visibility: hidden !important;
//         backface-visibility: hidden;
//         overflow: hidden;

//         &--back {
//             transform: rotateY(180deg);
//         }
//     }

//     &:hover &__side--back {
//         transform: rotateY(0);
//     }
//     &:hover &__side--front {
//         transform: rotateY(-180deg);
//     }
// }

.audit-info {
    .audit-card {
        display: flex;
        background-color: rgba(34, 58, 94, 0.9);
        min-height: 230px !important;

        .audit-symbol {
            transform: translateY(25%) translateX(15%);
        }

        p {
            // font-weight: 600;
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
            color: $text-color3;
        }
    }
}

.bg-picture1 {
    background-image: url("../../../assets/img/bg/hesabat-bg1.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-picture3 {
    background-image: url("../../../assets/img/bg/hesabat-bg3.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

.audit-page {
    .bg-picture2 {
        background-image: url("../../../assets/img/bg/hesabat-bg2.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .audit-card {
        height: 600px;
        background-color: rgba(34, 58, 94, 0.9) !important;

        &_side {
            transition: all 0.8s ease;
            -webkit-backface-visibility: hidden;
            /* We don't want to see the back part of the element. */
            backface-visibility: hidden;
            /* We don't want to see the back part of the element. */
            border-radius: 3px;

            .line {
                width: 145px;
                background-color: white;
                height: 1px;
            }

            &_back {
                transform: rotateY(180deg);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                button {
                    background: rgba(34, 58, 94, 0.8);
                    border: 2px solid #ffffff;
                    border-radius: 20px;
                    color: $text-color4;
                    font-style: italic;
                }
            }

            &_front {
                h2 {
                    font-size: 128px;
                    line-height: 154px;
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
}

.mida-mmc {
    .bg-picture2 {
        background-image: url("../../../assets/img/bg/hesabat-bg2.png");
        background-size: cover;
        background-repeat: no-repeat;
    }

    .audit-card {
        height: 400px;
        background: rgba(140, 162, 194, 0.9);

        &_side {
            transition: all 0.8s ease;
            -webkit-backface-visibility: hidden;
            /* We don't want to see the back part of the element. */
            backface-visibility: hidden;
            /* We don't want to see the back part of the element. */
            border-radius: 3px;

            .line {
                width: 145px;
                background-color: white;
                height: 1px;
            }

            &_back {
                transform: rotateY(180deg);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                button {
                    background: #223a5e;
                    border: 2px solid #ffffff;
                    border-radius: 20px;
                    color: $text-color4;
                    font-style: italic;
                }
            }

            &_front {
                h2 {
                    font-size: 6rem;
                    line-height: 6.5rem;
                    color: $text-color5;
                }
            }
        }
    }

    form {
        label {
            margin-left: 3px;
            color: $text-color1;
            margin-bottom: 0.1rem;
        }

        #send-btn {
            font-size: 12px;
            margin-left: auto;
            padding: 6px 15px !important;
            border-radius: 5px !important;
        }
    }
}

.custom-pagination {
    .custom-bullet {
        opacity: 1;
        background: $text-color5 !important;
        font-size: 1rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 12px;
        text-align: center;
        border-radius: 6px;

        &.swiper-pagination-bullet-active {
            background-color: $highlight !important;
        }
    }
}

.audit-card {
    position: relative;
    -moz-perspective: 200rem;
    perspective: 200rem;

    &_side {
        transition: all 0.8s ease;
        -webkit-backface-visibility: hidden;
        /* We don't want to see the back part of the element. */
        backface-visibility: hidden;
        /* We don't want to see the back part of the element. */
        border-radius: 3px;

        .line {
            width: 145px;
            background-color: white;
            height: 1px;
        }

        &_back {
            transform: rotateY(180deg);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            button {
                border: 2px solid #ffffff;
                border-radius: 20px;
                font-style: italic;
            }
        }

        &_front {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            top: 0;
            left: 0;
            width: 100%;

            h2 {
                transform: rotate(-90deg);
                font-weight: 500;
            }
        }
    }

    &:hover .audit-card_side_back {
        transform: rotateY(0);
    }

    &:hover .audit-card_side_front {
        transform: rotateY(-180deg);
    }
}

.flip-card {
    background-color: transparent;
    perspective: 1000px;
    /* add perspective to container */
}

/* flip the inner div on hover */
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
    transition: all 0.9s;
}

/* style the front and back sides of the card */
.flip-card-front,
.flip-card-back {
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* hide the back side of the card */
}

/* style the front side of the card */
.flip-card-front {
    position: absolute;
    transform: rotateY(0deg);
}

/* style the back side of the card */
.flip-card-back {
    transform: rotateY(180deg);
}

/* add transitions for smooth flipping */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

.mmc_nizamname_link {
    &:hover {
        color: inherit;
        text-decoration: underline !important;
    }
}

.sales-page-heading {
    div {
        color: $text-color4;
        padding: 0 3rem;
        text-align: center;
    }
}

@media (max-width: 700px) {
    .text-inf {
        font-size: 0.9rem;

        h6 {
            font-size: 20px;
        }

        p {
            font-size: 0.9rem;
            line-height: 1.7rem;
        }
    }

    #nizamname {
        // .text-inf {
        //     h6 {
        //         font-size: 20px;
        //     }

        //     p {
        //         font-size: 1rem;
        //         line-height: 2rem;
        //     }
        // }

        .txt-nz {
            text-align: start;
            font-size: 1.1rem;
            line-height: 28px;
        }
    }

    .management-page {
        h2 {
            font-size: 22px;
        }

        h5 {
            font-size: 20px;
        }
    }

    // .audit-card {
    //     width: 100% !important;
    // }

    .sales-page-heading {
        justify-content: center;

        div:first-child {
            font-size: 1.5rem !important;
            border-right: none !important;
        }

        div:nth-child(2) {
            font-size: 1rem !important;
            line-height: 1.5rem !important;
        }
    }
}