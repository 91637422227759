.breadcrumb {
    height: 50px;
    z-index: 100;
    
    .line {
        width: 100%;
        height: 3px;
        background-color: $text-color6;
    }

    .bc-items {
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translate(0, -50%);
        

        .bc-button {
            white-space: nowrap;
            border-radius: 8px;
            background: #8ca2c2;
            font-weight: 500;
            color: white;
            padding: 8px 12px;
            font-size: 1rem;
            line-height: 19px;
            max-width: 280px;
            text-align: center;
            height: max-content;
            margin: 0 2rem;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                background-color: #345079;
            }
        }

        .bc-button-active {
            background-color: $text-color6 !important;
        }

        // &>.bc-button:last-child {
        //     margin-right: 0;
        // }
    }

    .bc-unique {
        background-color: $highlight !important;
    }
}
@media (min-width: 1080px){
   .breadcrumb{
        margin-top: 1.5rem!important;
      }
   }

@media (max-width: 1080px) {
    .breadcrumb {
        height: auto;
        margin-top: 0!important;
        
        .line {
            display: none;
            // height: 2px;
        }

        .bc-items {
            transform: translate(0, 0);
            padding-left: 0;
            padding-right: 0;
            flex-wrap: wrap;
            margin-bottom: 1rem !important;
            justify-content: center;
            position: relative;
            top: 1.5rem;
          
            .bc-button {
                margin: 0.2rem 0.5rem !important;
                padding: 6px 12px;
                font-size: 0.7rem;
                max-width: 250px;
                text-align: center;
                height: max-content;
                cursor: pointer;
            }

            .bc-button-active {
                background-color: $text-color6 !important;
            }

            &>.bc-button:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 600px) {
    .breadcrumb {
        height: auto;
        margin-top: 0rem !important;

        .line {
            display: none;
            // height: 2px;
        }

        .bc-items {
            transform: translate(0, -20%) !important;
            padding-left: 0;
            padding-right: 0;
            flex-wrap: wrap;
            // margin-bottom: 1rem !important;
            justify-content: flex-start;
            // margin-top: 2rem;
            position: relative;
            top: 1.5rem;
            

            .bc-button {
                margin: 0.2rem !important;
                // width: 100%;
                padding: 6px 10px;
                max-width: 250px;
                text-align: center;
                height: max-content;
                cursor: pointer;
            }

            .bc-button-active {
                background-color: $text-color6 !important;
            }

            &>.bc-button:last-child {
                margin-right: 0;
            }
        }
    }
}