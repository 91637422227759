.main-gallery {
    position: fixed;
    z-index: 100;
    top: 0;
    height: 100vh;
    min-height: 900px;
    width: 100%;
    overflow: auto;
    background: rgba(217, 217, 217, 0.86);

    .gallery-vertical {
        max-height: 550px;
        justify-content: center;
        margin: auto;

        .swiper-main {
            img {
                width: 100%;
                max-height: 550px;
                border-radius: 16px;
            }
        }

        .swiper-side {
            .swiper-button-prev {
                display: none;
            }

            .swiper-button-next {
                bottom: 0;
                right: unset !important;
                top: unset !important;
                transform: rotate(90deg) translate(0, -215%);
            }

            .swiper-slide {
                margin: 2rem 0;

                img {
                    width: 100%;
                    min-height: 150px;
                    border-radius: 16px;
                }
            }
        }

        .swiper-slide {
            border-radius: 16px;
        }
    }
}

.gallery-vertical {
    display: flex;
    max-height: 700px;
    justify-content: space-between;
    overflow: hidden;

    .swiper {
        margin: 0;
    }

    .swiper-side {
        max-height: 550px;

        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
                font-size: 18px;
                background: #fff;

                /* Center slide text vertically */
                display: -webkit-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                -webkit-justify-content: center;
                justify-content: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
