// .news-item,
.announcements-item {
    .announcement-content {
        img {
            width: 100%;
        }
    }

    h5 {
        font-weight: 400 !important;
    }

    .small-heading {
        font-weight: 500;
        color: $text-color1;
    }

    small {
        font-weight: 400;
        color: $text-color1;

        &::before {
            content: "";
            border-bottom: 4px solid $text-color5;
            padding-left: 5.8rem;
            color: white;
            border-radius: 5px;
            position: absolute;
            bottom: 30px;
        }
    }
}

.announcement-page {
    .announcement-content {
        img {
            width: 100% !important;
            max-width: 100vw !important;
        }
    }

    h2 {
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #223a5e;
        font-family: "Roboto Condensed";
    }

    h5 {
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: justify;
        color: #223a5e;
    }

    .anns-link {
        background: #8ca2c2;
        border-radius: 5px;

        h6 {
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            text-decoration-line: underline;
            color: #ffffff;
        }
    }
}

@media (min-width: 798px) {
    .anns-link {
        width: calc((100% - 16px) / 3) !important;
    }
}

.coop-item {
    h5 {
        color: $text-color1;
        font-weight: 700;
    }

    .date {
        flex-wrap: wrap;
        width: 10%;
        font-style: italic;

        &::before {
            content: "";
            border-bottom: 4px solid $text-color5;
            padding-left: 5.8rem;
            color: white;
            border-radius: 5px;
            margin-bottom: 0.5rem;
        }
    }
}

.news-info {
    p,
    h5,
    h6 {
        color: $text-color1;
    }

    p {
        font-weight: 700;
    }

    h5 {
        line-height: 1.3rem;
    }

    small {
        font-weight: 400;
        color: rgba(78, 86, 90, 0.7);
        display: flex;
        align-items: center;
    }
}

.news-page {
    p {
        margin: 1.5rem 0;
    }

    // small {
    //     font-weight: 400;
    //     color: rgba(78, 86, 90, 0.7);
    //     display: flex;
    //     align-items: center;
    // }

    h4,
    h6 {
        color: $text-color1;
    }

    h4 {
        font-weight: 700;
    }

    .date {
        font-size: 15px;
    }
}

.news-item {
    // box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.25);
    .news-date {
        background: rgba(34, 58, 94, 0.8);
        color: $text-color4;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
    }

    .news-title {
        font-weight: 500;
        color: $text-color5;
    }
}

.news-item2 {
    min-height: 450px;
}

#news-page {
    .news-item {
        .news-date {
            font-size: 16px;
        }

        .news-title {
            font-size: 16px;
            line-height: 19.2px;
        }
    }
}

@media (min-width: 992px) {
    .news-main-pic {
        width: max-content;
    }
}

#news-home {
    .news-item {
        .news-date {
            font-size: 12px;
        }

        .news-title {
            font-size: 14px;
            line-height: 16.8px;
        }
    }
}

.multimedia {
    background-color: #142237;

    .big-link {
        font-weight: 400;
        font-family: "Roboto Condensed";
        font-size: 60px;
        line-height: 70px;
        text-transform: uppercase;
        // width: 50%;
        padding-bottom: 0.5rem;
        text-align: center;
        border-bottom: 1px solid white;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .gallery-horizontal {
        .swiper-pagination-fraction {
            bottom: 0px;
            left: 0;
            font-size: 22px;
            line-height: 26px;
            height: 50px;
            width: 100%;
            color: white;
            display: flex;
            background: rgba(140, 162, 194, 0.8);
            justify-content: center;
            align-items: center;
        }

        .swiper-main {
            border-radius: 0;

            img {
                border-radius: 0;
            }
        }

        .swiper-slide {
            border-radius: 0;

            img {
                border-radius: 0;
            }
        }
    }
}

@media (max-width: 1000px) {
    .multimedia {
        .big-link {
            font-size: 30px;
            padding-bottom: 2px;
        }

        &-link {
            div {
                justify-content: center !important;
            }
        }

        &-link:last-of-type {
            flex-direction: column-reverse;
        }
    }
}

#one-news-content {
    a {
        color: rgb(10, 88, 202) !important;
        text-decoration: underline !important;
    }
}
