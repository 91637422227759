.nav-tabs {
    border: none;

    .nav-item {
        // width: max-content;
        cursor: pointer;
        margin: 0;

        .nav-link {
            padding: 0.5rem 0 !important;
            margin-right: 5.5rem;
            color: $text-color3;
            font-weight: 500;
            text-align: center;
            border: none;

            &.active {
                color: $text-color5;
                background-color: transparent;
                border-top: 0;
                border-left: 0;
                border-right: 0;
                border-bottom: 2px solid $text-color5;
            }

            &:hover {
                border-top: 0;
                border-left: 0;
                border-right: 0;
                outline: none !important;
            }
        }
        
        &:hover {
            border: none !important;
            outline: none !important;
        }
    }
}
