@import "../../../theme/variables.scss";

.page {
  // padding-top: 54px;
  // min-height: 100vh;
  flex: 1;
  display: flex;
  flex-direction: column;
}

// @media screen and (min-width: 992px) {
//   .page {
//     padding-top: $header-height;
//   }
// }
