.interior_carousel {
  .carousel {
    display: flex;
    order: 3;
    margin-bottom: 20px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: static;
    color: black;

    &:hover,
    &:focus {
      color: black;
    }
  }

  .carousel-control-prev {
    order: 1;
  }

  .carousel-control-next {
    order: 3;
  }

  .carousel-inner {
    order: 2;
  }
}
