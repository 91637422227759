.info_panel {
  // border-top-left-radius: 33px;
  // border-bottom-left-radius: 33px;
  background: #8ca2c2;
  overflow: hidden;
  text-align: center;
  min-width: 20%;
  // position: relative;

  .info_panel_title {
    border-radius: 30px;
    background: #223a5e;
    padding: 15px;
    margin: 1rem 0.8rem 0 0.8rem;

    h5 {
      margin: 0;
      color: white;
    }
  }

  .info_panel_description {
    // padding: 20px;
    color: #000000;
  }

  p {
    text-align: start;
  }
}

@media screen and (max-width: 1100px) {
  .info_panel {
    &_title {
      padding: 8px !important;
    }
  }
}