.img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  filter: blur(8px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // z-index: -1;
  bottom: 0;
}

.overlay {
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // z-index: -1;
  bottom: 0;
}