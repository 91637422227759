.search-modal {
    .modal-dialog {
        width: 70vw !important;
        max-width: unset !important;
        margin: 0 auto !important;
    }

    .search-result {
        border: none;
        box-shadow: none;
        background-color: $bg1;
        color: $text-color5;

        &:hover {
            background-color: $text-color5;
            color: white;
        }
    }

    .modal-content {
        height: 80vh;
    }
}

@media (max-width: 800px) {
    .search-modal {
        .modal-dialog {
            width: 100% !important;
            margin: 0 !important;
            padding: 0.5rem !important;
            height: 100%;
        }

        .modal-content {
            height: unset !important;
        }
    }
}