.status-label-flag {
    top: -50px;
    right: -35.7px;
    width: 103px;
}

#gm-icon {
    height: 77%;
}

@media (max-width: 700px) {
    #gm-icon {
        width: 100%;
        height: 60%;
    }

    .link-box {
        padding: 0 !important;

        a {
            top: 0 !important;
        }
    }
}

@media (max-width: 1000px) {
    #gm-icon {
        width: 100%;
        height: 68%;
    }
}

@media (max-width: 770px) {
    .home-stats-item {
        img {
            width: 70px !important;
            height: 70px !important;
            margin: 0 20px 0 20px;
        }
    }
}

@media (max-width: 500px) {
    .status-label-flag {
        top: -20px !important;
        right: -10.5px;
        width: 33px;
    }

    .home-stats-item {
        img {
            width: 50px !important;
            height: 50px !important;
            margin-right: 10px;
        }

        div {
            padding: 0 !important;
        }

        small {
            font-size: 9px !important;
        }

        small,
        h2 {
            line-height: 13px !important;
            margin-bottom: 0 !important;
            display: flex;
        }

        h2 {
            font-size: 11px !important;
            margin-top: 2px;
        }
    }
}

@media (max-width: 800px) {
    .status-label-flag {
        top: 3px;
        right: -16px;
        width: 50px;
    }

    .status-label-flag-proj {
        top: 3px;
        right: 0 !important;
        width: 95px;
    }
}

.block7 {
    .highlight-section {
        margin: 3rem 0 1.5rem 0;

        &-content {
            padding: 0.5rem 0;

            h5 {
                // font-weight: 600;
                font-weight: 700;
                color: $text-color5;
                margin-bottom: 0;
            }
        }
    }
}

.home-biglinks {
    small {
        color: $text-color1;
        font-weight: 500;
        line-height: 22px;
        margin-left: 1.25rem;
        margin-bottom: 0;
    }
}

.home-projects {
    .bc-button {
        max-width: unset !important;
    }
}

.mainpage-links {
    justify-content: space-between;
    // align-items: center;

    .mainpage-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0 1rem;
    }

    p {
        font-family: "Roboto Condensed";
    }
}

@media (min-width: 992px) {
    .home-stats {
        .block-content {
            flex-wrap: nowrap;
        }
    }
}

@media (max-width: 550px) {
    .mainpage-links {
        img {
            max-width: 100px;
        }

        p {
            font-size: 9px !important;
            margin-bottom: 0 !important;
            line-height: unset !important;
        }
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .mainpage-links {
        img {
            height: 95%;
            min-width: 75px;
        }
    }
}
