.reports-charts {
    #table2,
    #table4,
    #table9,
    #table10,
    #table11,
    #table12,
    #table14,
    #table15 {
        tbody td:last-of-type {
            background-color: #8ba2c2!important;
           
        }

        tbody tr:last-of-type {
            background-color: #8ba2c2 !important;
            color: white !important;
           
        }
    }

    #table17 {
         .total {
            background-color: #8ba2c2!important; 
            font-size: 18px;
            border-color: #223a5e !important;
            border: solid 1px;
         }
    }

    th,
    tr,
    td {
        vertical-align: middle;
        text-align: center;
        padding: 0.25rem 0.3rem !important;
    }

    // th {
    //     white-space: nowrap;
    // }

    thead th {
        background-color: #223a5e;
        color: white;
    }

    tbody {
        border-color: #223a5e !important;
        color: #223a5e !important;
        font-weight: 600;
    }

    .w-100 {
        width: 100%;
    }
}

.table-header {
    background-color: #f79647 !important;
    color: #223a5e !important;
}

.chart-header {
    background-color: #8ba2c2;
    color: white;
    padding: 0 3rem;
    display: flex;
    align-items: center;

    h6 {
        font-size: 1.3rem;
    }
}

.chart-container {
    border-top: #223a5e 3px solid;
    border-bottom: #223a5e 3px solid;
    margin-bottom: 1rem;
}

.temp1-stats {
    h1,
    h4 {
        color: #223a5e;
    }
}

@media (min-width: 992px) {
    .reports-charts {
        max-width: 1350px !important;
    }
}

@media screen and (max-width: 768px) {
    .table-responsive th,
    .table-responsive td {
        max-width: 60px;
        font-size: 0.6rem;
    }

    .table-category-header {
        min-width: 80px;
    }
}

@media screen and (max-width: 576px) {
    .table-responsive th,
    .table-responsive td {
        max-width: 40px;
        font-size: 0.5rem;
    }

    .table-category-header {
        min-width: 60px;
    }
}

@media screen and (max-width: 520px) {
    .table-responsive th,
    .table-responsive td {
        max-width: 30px;
        font-size: 0.35rem;
    }

    .table-category-header {
        min-width: 50px;
    }
}
