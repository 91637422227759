.gallery-horizontal {
    max-height: 750px;
    justify-content: center;
    margin: auto;

    .swiper-main {
        max-height: 500px;
        border-radius: 16px;

        img {
            width: 100% !important;
            object-fit: cover !important;
            // max-height: 400px;
            height: auto !important;
            border-radius: 16px;
        }
    }

    // .swiper-side {
    //     // .swiper-button-prev {
    //     //     display: none;
    //     // }

    //     // .swiper-button-next {
    //     //     bottom: 0;
    //     //     right: unset !important;
    //     //     top: unset !important;
    //     //     transform: rotate(90deg) translate(0, -215%);
    //     // }

    .swiper-slide {
        margin: 2rem 0;
        border-radius: 16px;
        height: 470px !important;

        img {
            width: 100%;
            height: 100%;
            border-radius: 16px;
        }
        // }
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-slide {
        background-size: cover;
        background-position: center;
    }

    .mySwiper {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
    }

    .mySwiper .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;
    }

    .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
