$text-color1: #4E565A;
$text-color2: #545353;
$text-color3: #103540;
$text-color4: #FFFFFF;
// $text-color5: #1A90B9;
$text-color5: #223A5E;
$text-color6: #223A5E;
// $text-color6: #3394BC;
$highlight: #F8851B;

$bg1: #F5F5F7;
$bg2: #E7F9FF;
$bg3: #F3FCFF;
$bg4: #8aa1c1;