small,
.footer-heading-link,
.small-heading {
  font-size: 1rem;
  line-height: 1.05rem;
}

.footer-link {
  font-size: 1rem;
  line-height: 2rem;
}

p,
.li,
.nav-tabs .nav-link {
  font-size: 1.125rem;
}

p {
  line-height: 1.375rem;
}

.li,
.nav-tabs .nav-link {
  line-height: 2rem;
}

.btn {
  font-size: 1rem;
  line-height: 1.5rem;
}

.h4 {
  font-size: 1.375rem;
}

.page-link,
h5 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h6 {
  font-size: 1.125rem;
}

.small {
  font-weight: 500 !important;
  color: #4E565A !important;
}

@media (max-width: 700px) {
  .section-heading {
    font-size: 1.5rem !important;
  }
  .page-link,
  h5, h4 {
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }
  h6 {
    font-size: 0.9rem !important;
  }
  p,
  .li,
  .nav-tabs .nav-link {
    font-size: 0.9rem !important;
  }
  p {
    line-height: 1.1rem !important;
  }
  .navmobile-link {
    font-size: 1.2rem !important;
  }
}
.medium h1 {
  font-size: 3.75rem;
  line-height: 6.75rem;
}
.medium h5 {
  font-size: 1.875rem;
}
.medium h6,
.medium small,
.medium .footer-heading-link {
  font-size: 1.5rem;
  line-height: 1.575rem;
}
.medium .footer-link {
  font-size: 1.5rem;
  line-height: 3rem;
}
.medium p,
.medium .li,
.medium .nav-tabs .nav-link {
  font-size: 1.7rem;
}
.medium p {
  line-height: 2rem;
}
.medium .li,
.medium .nav-tabs .nav-link {
  line-height: 3rem;
}
.medium .btn {
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.large h1 {
  font-size: 5rem;
  line-height: 9rem;
}
.large h5 {
  font-size: 2.5rem;
}
.large h6,
.large small,
.large .footer-heading-link {
  font-size: 2rem;
  line-height: 2.1rem;
}
.large .footer-link {
  font-size: 3rem;
  line-height: 4rem;
}
.large p,
.large .li,
.large .nav-tabs .nav-link {
  font-size: 2.25rem;
}
.large p {
  line-height: 2.75rem;
}
.large .li,
.large .nav-tabs .nav-link {
  line-height: 4rem;
}
.large .btn {
  font-size: 2rem;
  line-height: 3rem;
}

body {
  margin: 0;
  font-family: "Helvetica" !important;
  background-color: #F5F5F7;
  overflow-x: hidden !important;
}

@media (max-width: 1000px) {
  #root {
    width: 100vw;
    overflow: hidden;
  }
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/Roboto Condensed/RobotoCondensed-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/Roboto Condensed/RobotoCondensed-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../../fonts/Roboto Condensed/RobotoCondensed-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Helvetica";
  src: url("../../fonts/Helvetica/HelveticaNowText-Bold.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Helvetica";
  src: url("../../fonts/Helvetica/HelveticaNowText-Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Helvetica";
  src: url("../../fonts/Helvetica/HelveticaNowText-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Helvetica";
  src: url("../../fonts/Helvetica/HelveticaNowText-RegIta.otf");
  font-weight: 400;
  font-style: italic;
}
a {
  text-decoration: none !important;
}

.block-bg1 {
  background-color: #F5F5F7;
}

.block-bg2 {
  background-color: #E7F9FF;
}

.block-bg3 {
  background-color: #223A5E;
}

.block-bg4 {
  background-color: #8aa1c1;
}

.bg-highlight {
  background-color: #F8851B;
}

.block-bg-white {
  background-color: white;
}

.small-heading {
  color: #103540;
}

.heading {
  font-family: "Roboto Condensed";
}

.h-0 {
  height: 0 !important;
}

.px-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.px-120 {
  padding-left: 120px;
  padding-right: 120px;
}

.w-80 {
  width: 80% !important;
}

.pagination .btn-outline-primary {
  background: rgb(34, 58, 94) !important;
  border: none !important;
  color: white !important;
  opacity: 1 !important;
  margin: 0 0.3rem;
}
.pagination .btn-outline-primary.active {
  background: #f89336 !important;
}

.swiper-button-next-n,
.swiper-button-prev-n {
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.limit-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  min-height: 4.125rem;
}

@media (min-width: 1080px) {
  .pt-md-60 {
    padding-top: 60px !important;
  }
  .pt-md-80 {
    padding-top: 80px !important;
  }
  .pb-md-80 {
    padding-bottom: 80px !important;
  }
  .py-md-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .px-md-180 {
    padding-left: 180px !important;
    padding-right: 180px !important;
  }
  .px-md-120 {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .px-md-60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .mt-md-90 {
    margin-top: 90px;
  }
  .mt-md-120 {
    margin-top: 120px;
  }
}
.t-color1 {
  color: #4E565A;
}

.t-color2 {
  color: #545353;
}

.t-color3 {
  color: #103540;
}

.t-color4 {
  color: #FFFFFF !important;
}

.t-color5 {
  color: #223A5E;
}

.t-color6 {
  color: #223A5E;
}

.t-black {
  color: black !important;
}

.highlight {
  color: #F8851B;
}

.text-gradient {
  background: linear-gradient(to bottom, #4e525a 0%, rgba(78, 82, 90, 0.7) 50%, rgba(78, 82, 90, 0.4) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-justify {
  text-align: justify;
}

@media (min-width: 1200px) {
  .w-md-75 {
    width: 75% !important;
  }
  .h-md-75 {
    height: 75% !important;
  }
}
.fw-700 {
  font-weight: 700 !important;
}

.fw-500 {
  font-weight: 500;
}

.section-heading {
  font-weight: 500;
  line-height: 2.375rem;
  font-size: 2rem;
  color: #103540;
  font-family: "Roboto Condensed", sans-serif !important;
}

.card {
  background: #ffffff;
  border: 1px solid #223A5E;
  border-radius: 16px;
  padding: 33px;
}

.hover-img {
  transition: all 0.5s;
}
.hover-img:hover {
  transform: scale(1.03) !important;
}

.hover-text-shift {
  transition: all 0.5s;
}
.hover-text-shift:hover {
  margin-left: 1rem !important;
}

.hover-text-underline {
  transition: all 0.5s;
}
.hover-text-underline:hover {
  text-decoration: underline !important;
}

.announcements-item .label {
  position: absolute;
  top: -30px;
  font-size: 1rem;
  line-height: 19px;
  background: #F8851B !important;
  border-radius: 8px;
  padding: 3px 27px;
  color: white;
}

@media (max-width: 1000px) {
  .d-mob-none {
    display: none;
  }
}
@media (max-width: 800px) {
  body .uwy.userway_p1 .uai {
    top: 95px !important;
  }
}
.fs-5_5 {
  font-size: 1.125rem !important;
}

.cursor {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline !important;
}

.apt-search-modal-table > tbody > tr:hover > * {
  color: white !important;
  background-color: red;
}

.swiper-button-prev-n,
.swiper-button-next-n {
  position: absolute;
  top: 60%;
  cursor: pointer;
}

.swiper-button-prev-n {
  left: 0;
  transform: translate(-150%, -245%);
}

.swiper-button-next-n {
  right: 0;
  transform: translate(175%, -245%);
}

.swiper-button-prev-n.swiper-button-disabled,
.swiper-button-next-n.swiper-button-disabled {
  opacity: 0.35;
}

@media (min-width: 500px) {
  .w-md-31 {
    width: 31% !important;
  }
}
@media (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
@media (max-width: 900px) {
  .w-sm-50 {
    width: 50% !important;
  }
}
.w-12 {
  width: 12% !important;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #103540;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.nav-tabs {
  border: none;
}
.nav-tabs .nav-item {
  cursor: pointer;
  margin: 0;
}
.nav-tabs .nav-item .nav-link {
  padding: 0.5rem 0 !important;
  margin-right: 5.5rem;
  color: #103540;
  font-weight: 500;
  text-align: center;
  border: none;
}
.nav-tabs .nav-item .nav-link.active {
  color: #223A5E;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #223A5E;
}
.nav-tabs .nav-item .nav-link:hover {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  outline: none !important;
}
.nav-tabs .nav-item:hover {
  border: none !important;
  outline: none !important;
}

.accordion-button::after {
  transform: rotate(-90deg) !important;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) !important;
}

.accordion-item {
  background: transparent !important;
  border: none;
}
.accordion-item button,
.accordion-item div,
.accordion-item .accordion-header {
  background: transparent;
}
.accordion-item .accordion-button {
  font-weight: 700;
  color: #103540;
  font-size: 1.25rem;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion-item .accordion-collapse p {
  color: #103540;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.main-gallery {
  position: fixed;
  z-index: 100;
  top: 0;
  height: 100vh;
  min-height: 900px;
  width: 100%;
  overflow: auto;
  background: rgba(217, 217, 217, 0.86);
}
.main-gallery .gallery-vertical {
  max-height: 550px;
  justify-content: center;
  margin: auto;
}
.main-gallery .gallery-vertical .swiper-main img {
  width: 100%;
  max-height: 550px;
  border-radius: 16px;
}
.main-gallery .gallery-vertical .swiper-side .swiper-button-prev {
  display: none;
}
.main-gallery .gallery-vertical .swiper-side .swiper-button-next {
  bottom: 0;
  right: unset !important;
  top: unset !important;
  transform: rotate(90deg) translate(0, -215%);
}
.main-gallery .gallery-vertical .swiper-side .swiper-slide {
  margin: 2rem 0;
}
.main-gallery .gallery-vertical .swiper-side .swiper-slide img {
  width: 100%;
  min-height: 150px;
  border-radius: 16px;
}
.main-gallery .gallery-vertical .swiper-slide {
  border-radius: 16px;
}

.gallery-vertical {
  display: flex;
  max-height: 700px;
  justify-content: space-between;
  overflow: hidden;
}
.gallery-vertical .swiper {
  margin: 0;
}
.gallery-vertical .swiper-side {
  max-height: 550px;
}
.gallery-vertical .swiper-side .swiper-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-vertical .swiper-side .swiper-wrapper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.gallery-horizontal {
  max-height: 750px;
  justify-content: center;
  margin: auto;
}
.gallery-horizontal .swiper-main {
  max-height: 500px;
  border-radius: 16px;
}
.gallery-horizontal .swiper-main img {
  width: 100% !important;
  -o-object-fit: cover !important;
     object-fit: cover !important;
  height: auto !important;
  border-radius: 16px;
}
.gallery-horizontal .swiper-slide {
  margin: 2rem 0;
  border-radius: 16px;
  height: 470px !important;
}
.gallery-horizontal .swiper-slide img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
.gallery-horizontal .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-horizontal .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.gallery-horizontal .swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-horizontal .mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}
.gallery-horizontal .mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}
.gallery-horizontal .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.gallery-horizontal .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.more-info-btn {
  border-radius: 50px;
  background-color: #223A5E;
  color: white !important;
  font-weight: 700;
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s;
}
.more-info-btn small {
  font-size: 1rem !important;
  line-height: 24px !important;
}
.more-info-btn:hover {
  transform: scale(1.03);
}

.btn-search {
  background-color: #223a5e;
  display: flex;
  align-items: center;
  border-radius: 50px;
  border: none;
  color: white;
  padding: 0.8rem;
}

.button-tp {
  border: 1px solid #4E565A;
  border-radius: 4px;
  background-color: transparent;
  font-weight: 500;
  font-size: 16px;
  text-align: justify;
  color: #4E565A;
  padding: 0.5rem 1rem;
}

.btn-custom {
  background: #223A5E !important;
  border-radius: 8px !important;
  color: #FFFFFF !important;
  letter-spacing: 0.04em !important;
}
.btn-custom:hover {
  color: white !important;
  background: #192942 !important;
}
.btn-custom:active {
  color: white !important;
  background: #192942 !important;
}

.btn-show-more {
  background: #8ca2c2;
  border-radius: 50px;
  color: white;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 1.8rem;
  border: none;
}
.btn-show-more:hover {
  background: #223a5e;
}

.btn-share {
  padding: 0.1rem 0.7rem 0.1rem 0;
  color: white !important;
  background: #223A5E;
  border-radius: 20px;
  cursor: pointer;
}
.btn-share img {
  margin-right: 0.5rem;
}
.btn-share:hover {
  color: white;
  text-decoration: underline !important;
}

.btn-gms {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #76859b;
  border-radius: 5px;
  display: flex;
  align-items: center;
  min-width: 230px !important;
}
.btn-gms small {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #76859b;
}

.home-main-btn {
  background-color: #223a5e;
  opacity: 0.8;
  width: 100%;
  height: 160px !important;
  padding: 1rem 0;
  border-radius: 12px;
}
.home-main-btn img {
  height: 100%;
  padding: 0;
  margin: auto 0;
}
.home-main-btn:hover {
  opacity: 1;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .home-main-btn {
    height: 130px !important;
  }
}
@media (max-width: 768px) and (max-width: 992px) {
  .mainpage-link {
    padding: 0 0 !important;
  }
  .mainpage-link p {
    font-size: 0.8rem;
  }
  .home-main-btn {
    height: 125px !important;
  }
  .home-main-btn img {
    padding: 0;
    margin: auto 0;
  }
}
@media (max-width: 767px) {
  .home-main-btn {
    height: 100% !important;
  }
}
@media (max-width: 600px) {
  .home-main-btn {
    height: unset !important;
  }
}
@media (max-width: 1100px) {
  .more-info-btn,
  .btn-show-more {
    transform: scale(0.8);
  }
  .btn-container {
    justify-content: center !important;
  }
}
.breadcrumb {
  height: 50px;
  z-index: 100;
}
.breadcrumb .line {
  width: 100%;
  height: 3px;
  background-color: #223A5E;
}
.breadcrumb .bc-items {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(0, -50%);
}
.breadcrumb .bc-items .bc-button {
  white-space: nowrap;
  border-radius: 8px;
  background: #8ca2c2;
  font-weight: 500;
  color: white;
  padding: 8px 12px;
  font-size: 1rem;
  line-height: 19px;
  max-width: 280px;
  text-align: center;
  height: -moz-max-content;
  height: max-content;
  margin: 0 2rem;
  cursor: pointer;
  transition: all 0.5s;
}
.breadcrumb .bc-items .bc-button:hover {
  background-color: #345079;
}
.breadcrumb .bc-items .bc-button-active {
  background-color: #223A5E !important;
}
.breadcrumb .bc-unique {
  background-color: #F8851B !important;
}

@media (min-width: 1080px) {
  .breadcrumb {
    margin-top: 1.5rem !important;
  }
}
@media (max-width: 1080px) {
  .breadcrumb {
    height: auto;
    margin-top: 0 !important;
  }
  .breadcrumb .line {
    display: none;
  }
  .breadcrumb .bc-items {
    transform: translate(0, 0);
    padding-left: 0;
    padding-right: 0;
    flex-wrap: wrap;
    margin-bottom: 1rem !important;
    justify-content: center;
    position: relative;
    top: 1.5rem;
  }
  .breadcrumb .bc-items .bc-button {
    margin: 0.2rem 0.5rem !important;
    padding: 6px 12px;
    font-size: 0.7rem;
    max-width: 250px;
    text-align: center;
    height: -moz-max-content;
    height: max-content;
    cursor: pointer;
  }
  .breadcrumb .bc-items .bc-button-active {
    background-color: #223A5E !important;
  }
  .breadcrumb .bc-items > .bc-button:last-child {
    margin-right: 0;
  }
}
@media (max-width: 600px) {
  .breadcrumb {
    height: auto;
    margin-top: 0rem !important;
  }
  .breadcrumb .line {
    display: none;
  }
  .breadcrumb .bc-items {
    transform: translate(0, -20%) !important;
    padding-left: 0;
    padding-right: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    top: 1.5rem;
  }
  .breadcrumb .bc-items .bc-button {
    margin: 0.2rem !important;
    padding: 6px 10px;
    max-width: 250px;
    text-align: center;
    height: -moz-max-content;
    height: max-content;
    cursor: pointer;
  }
  .breadcrumb .bc-items .bc-button-active {
    background-color: #223A5E !important;
  }
  .breadcrumb .bc-items > .bc-button:last-child {
    margin-right: 0;
  }
}
.search-modal .modal-dialog {
  width: 70vw !important;
  max-width: unset !important;
  margin: 0 auto !important;
}
.search-modal .search-result {
  border: none;
  box-shadow: none;
  background-color: #F5F5F7;
  color: #223A5E;
}
.search-modal .search-result:hover {
  background-color: #223A5E;
  color: white;
}
.search-modal .modal-content {
  height: 80vh;
}

@media (max-width: 800px) {
  .search-modal .modal-dialog {
    width: 100% !important;
    margin: 0 !important;
    padding: 0.5rem !important;
    height: 100%;
  }
  .search-modal .modal-content {
    height: unset !important;
  }
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #223A5E;
  margin: -4px 0 0 -4px;
}

.lds-roller.white div:after {
  background: #fff;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.reports-charts #table2 tbody td:last-of-type,
.reports-charts #table4 tbody td:last-of-type,
.reports-charts #table9 tbody td:last-of-type,
.reports-charts #table10 tbody td:last-of-type,
.reports-charts #table11 tbody td:last-of-type,
.reports-charts #table12 tbody td:last-of-type,
.reports-charts #table14 tbody td:last-of-type,
.reports-charts #table15 tbody td:last-of-type {
  background-color: #8ba2c2 !important;
}
.reports-charts #table2 tbody tr:last-of-type,
.reports-charts #table4 tbody tr:last-of-type,
.reports-charts #table9 tbody tr:last-of-type,
.reports-charts #table10 tbody tr:last-of-type,
.reports-charts #table11 tbody tr:last-of-type,
.reports-charts #table12 tbody tr:last-of-type,
.reports-charts #table14 tbody tr:last-of-type,
.reports-charts #table15 tbody tr:last-of-type {
  background-color: #8ba2c2 !important;
  color: white !important;
}
.reports-charts #table17 .total {
  background-color: #8ba2c2 !important;
  font-size: 18px;
  border-color: #223a5e !important;
  border: solid 1px;
}
.reports-charts th,
.reports-charts tr,
.reports-charts td {
  vertical-align: middle;
  text-align: center;
  padding: 0.25rem 0.3rem !important;
}
.reports-charts thead th {
  background-color: #223a5e;
  color: white;
}
.reports-charts tbody {
  border-color: #223a5e !important;
  color: #223a5e !important;
  font-weight: 600;
}
.reports-charts .w-100 {
  width: 100%;
}

.table-header {
  background-color: #f79647 !important;
  color: #223a5e !important;
}

.chart-header {
  background-color: #8ba2c2;
  color: white;
  padding: 0 3rem;
  display: flex;
  align-items: center;
}
.chart-header h6 {
  font-size: 1.3rem;
}

.chart-container {
  border-top: #223a5e 3px solid;
  border-bottom: #223a5e 3px solid;
  margin-bottom: 1rem;
}

.temp1-stats h1,
.temp1-stats h4 {
  color: #223a5e;
}

@media (min-width: 992px) {
  .reports-charts {
    max-width: 1350px !important;
  }
}
@media screen and (max-width: 768px) {
  .table-responsive th,
  .table-responsive td {
    max-width: 60px;
    font-size: 0.6rem;
  }
  .table-category-header {
    min-width: 80px;
  }
}
@media screen and (max-width: 576px) {
  .table-responsive th,
  .table-responsive td {
    max-width: 40px;
    font-size: 0.5rem;
  }
  .table-category-header {
    min-width: 60px;
  }
}
@media screen and (max-width: 520px) {
  .table-responsive th,
  .table-responsive td {
    max-width: 30px;
    font-size: 0.35rem;
  }
  .table-category-header {
    min-width: 50px;
  }
}
header {
  background-color: #F5F5F7;
}
header .container-md {
  padding: 0 !important;
}
header .invisible {
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s ease-in-out;
}
header .visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0.5s, opacity 0.5s ease-out;
}
header nav .nav-menu {
  display: flex;
}
header nav .nav-menu a {
  color: #223A5E;
  letter-spacing: 0.02em;
  font-size: 1.25rem;
}
header nav .nav-menu > div:first-child {
  margin-left: -1rem;
}
header nav .menu-buttons-desktop {
  display: flex;
}
header nav .dropdown-menu {
  background: #223A5E;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 250px !important;
  border: none;
  border-radius: 0 !important;
}
header nav .dropdown-menu a {
  font-size: 1rem;
  color: white;
}
header .libraries a {
  color: #223A5E;
  cursor: pointer;
}
header .libraries a:hover {
  text-decoration: underline;
}
header .nav-menu-mobile,
header .nav-menu-mobile-icon {
  display: none;
}
header .nav-menu-mobile-icon {
  color: #4E565A;
  font-size: 2rem;
}
header .nav-menu-mobile {
  height: -moz-max-content;
  height: max-content;
  transition: display 1s linear;
}
header .nav-menu-mobile .navmobile-heading-link {
  font-weight: 700;
  color: #223A5E;
  font-size: 1.5rem;
  text-decoration: underline;
}
header .nav-menu-mobile .navmobile-link {
  font-weight: 400;
  color: #223A5E;
  font-size: 1rem !important;
}
header .mob-show {
  display: flex !important;
}
header .search {
  display: flex;
  align-items: center;
  color: #F8851B;
}
header .languages-desktop a {
  color: #223A5E;
  margin-left: 30px;
}
header .languages-desktop .lang-active {
  font-weight: 500;
}
header .social-links img {
  width: 24px;
  height: 24px;
}

/* Hamburger Menu */
.menu-icon {
  position: relative;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.menu-icon .menu-icon__cheeckbox {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  z-index: 2;
  -webkit-touch-callout: none;
  position: absolute;
  opacity: 0;
}
.menu-icon div {
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 22px;
  height: 12px;
}
.menu-icon span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--bar-bg, #000);
  border-radius: 1px;
  transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);
}
.menu-icon span:first-of-type {
  top: 0;
}
.menu-icon span:last-of-type {
  bottom: 0;
}
.menu-icon.active span:first-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:first-of-type {
  transform: rotate(45deg);
  top: 5px;
}
.menu-icon.active span:last-of-type,
.menu-icon .menu-icon__cheeckbox:checked + div span:last-of-type {
  transform: rotate(-45deg);
  bottom: 5px;
}
.menu-icon.active:hover span:first-of-type, .menu-icon.active:hover span:last-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:first-of-type, .menu-icon:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
  width: 22px;
}
@media (min-width: 1024px) {
  .menu-icon:hover span:first-of-type {
    width: 26px;
  }
  .menu-icon:hover span:last-of-type {
    width: 12px;
  }
}

.dropdown-toggle::after {
  border: none !important;
}

.dropdown-toggle {
  font-family: "Roboto Condensed", sans-serif !important;
}

.dropdown-menu {
  display: none;
  width: 210px;
  width: -moz-max-content;
  width: max-content;
  left: 0;
}

.dropdown-item {
  white-space: unset !important;
  width: 100%;
  padding: 0 1.2rem !important;
  margin: 0.5rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 19px;
  transition: 0.3s;
}
.dropdown-item:hover {
  padding-left: 1.4rem !important;
  background: transparent !important;
  color: white !important;
  text-decoration: underline !important;
}
.dropdown-item:focus, .dropdown-item:active {
  background-color: transparent !important;
}

.dropdown-hover-btn {
  width: -moz-max-content;
  width: max-content;
  padding: 0.2rem 1rem;
}
.dropdown-hover-btn.active {
  background: #223A5E;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.dropdown-hover-btn.active a {
  color: white !important;
}

.dropdown-hover-btn:hover {
  background: #223A5E;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.dropdown-hover-btn:hover .dropdown-menu,
.dropdown-hover-btn:hover .dropdown-menu:hover {
  display: block !important;
}
.dropdown-hover-btn:hover a {
  color: white;
}

.search-mob-holder {
  border: 1px solid #F8851B;
}
.search-mob-holder .search-mob {
  border: none;
  height: 60px;
  border-radius: 0;
  outline: none;
  font-size: 1.2rem;
  color: #F8851B;
}
.search-mob-holder .search-mob:focus {
  box-shadow: none;
}
.search-mob-holder .search-mob::-moz-placeholder {
  color: #F8851B;
  font-size: 1.2rem;
}
.search-mob-holder .search-mob::placeholder {
  color: #F8851B;
  font-size: 1.2rem;
}

.languages-desktop a,
.libraries a {
  font-family: "Roboto Condensed" !important;
  font-weight: 300;
}

.languages-mobile a {
  color: #223A5E;
}

@media (max-width: 992px) {
  header {
    border-bottom: 1px solid lightgrey;
  }
  .nav-menu,
  .search-desktop,
  .languages-desktop,
  .social-links-desktop,
  .libraries-desktop,
  .menu-buttons-desktop,
  .menu-shrinked-desktop,
  .logo-text {
    display: none !important;
  }
  .nav-menu-mobile-icon,
  .search-mobile {
    display: block !important;
  }
}
@media (max-width: 792px) {
  header {
    height: auto;
  }
  nav .logo-text {
    width: 70%;
  }
}
footer {
  background-color: #223A5E;
}
footer .footer-heading-link {
  font-weight: 500;
  letter-spacing: 0.06em;
  font-size: 1.25rem;
  font-family: "Roboto Condensed";
  color: white !important;
  text-transform: uppercase;
  margin-bottom: 0.8rem;
}
footer .footer-link {
  font-weight: 400;
  letter-spacing: 0.03em;
  line-height: 2rem;
  font-size: 1rem;
  color: white;
  margin: 0.1rem 0;
}
footer .footer-link:hover {
  color: white;
  text-decoration: underline !important;
}
footer small {
  font-weight: 400;
  color: white;
}
footer .mt-90 {
  margin-top: 90px;
}
footer .mt-30 {
  margin-top: 30px;
}
footer .my-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}
footer .mt-15 {
  margin-top: 15px;
}

@media (max-width: 1000px) {
  footer .footer-col {
    padding: 0.5rem !important;
  }
  footer .footer-col .footer-logo {
    transform: scale(0.9);
    display: none !important;
  }
}
.slider {
  width: 100%;
  position: relative;
  aspect-ratio: auto 3.3/1;
}
.slider .swiper-slide-btn {
  top: 50% !important;
  transform: translateY(-50%) !important;
}
.slider .swiper {
  overflow-x: hidden !important;
  overflow-y: visible !important;
  position: static !important;
}
.slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider .swiper-slide .slider-text {
  margin-bottom: 1.7rem;
  background: rgba(34, 58, 94, 0.8);
}
.slider .swiper-slide .slider-text small {
  display: flex;
  text-align: start;
  color: #FFFFFF;
}
.slider .swiper-horizontal > .swiper-pagination-bullets,
.slider .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: unset !important;
  margin-top: -1.4rem;
}
.slider .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  opacity: 1;
  margin: 0 6px !important;
  background: #d9d9d9 !important;
}
.slider .swiper-pagination-bullet-active {
  background: #223A5E !important;
}
.slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 992px) {
  .slider .swiper-slide .slider-text h6 {
    font-size: 17px;
    color: #545353;
  }
}

@media (max-width: 992px) {
  .slider {
    height: calc(50vh - 128px);
  }
}
@media (max-width: 500px) {
  .slider .swiper-pagination-bullet {
    width: 8px !important;
    height: 8px !important;
    margin: 0 6px !important;
  }
  .slider .swiper-horizontal > .swiper-pagination-bullets,
  .slider .slider .swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-top: 0.5rem !important;
  }
  .slider .slider-text small {
    font-size: 14px;
  }
  .slider .swiper-slide:first-of-type img {
    -o-object-position: left !important;
       object-position: left !important;
  }
}
.status-label-flag {
  top: -50px;
  right: -35.7px;
  width: 103px;
}

#gm-icon {
  height: 77%;
}

@media (max-width: 700px) {
  #gm-icon {
    width: 100%;
    height: 60%;
  }
  .link-box {
    padding: 0 !important;
  }
  .link-box a {
    top: 0 !important;
  }
}
@media (max-width: 1000px) {
  #gm-icon {
    width: 100%;
    height: 68%;
  }
}
@media (max-width: 770px) {
  .home-stats-item img {
    width: 70px !important;
    height: 70px !important;
    margin: 0 20px 0 20px;
  }
}
@media (max-width: 500px) {
  .status-label-flag {
    top: -20px !important;
    right: -10.5px;
    width: 33px;
  }
  .home-stats-item img {
    width: 50px !important;
    height: 50px !important;
    margin-right: 10px;
  }
  .home-stats-item div {
    padding: 0 !important;
  }
  .home-stats-item small {
    font-size: 9px !important;
  }
  .home-stats-item small,
  .home-stats-item h2 {
    line-height: 13px !important;
    margin-bottom: 0 !important;
    display: flex;
  }
  .home-stats-item h2 {
    font-size: 11px !important;
    margin-top: 2px;
  }
}
@media (max-width: 800px) {
  .status-label-flag {
    top: 3px;
    right: -16px;
    width: 50px;
  }
  .status-label-flag-proj {
    top: 3px;
    right: 0 !important;
    width: 95px;
  }
}
.block7 .highlight-section {
  margin: 3rem 0 1.5rem 0;
}
.block7 .highlight-section-content {
  padding: 0.5rem 0;
}
.block7 .highlight-section-content h5 {
  font-weight: 700;
  color: #223A5E;
  margin-bottom: 0;
}

.home-biglinks small {
  color: #4E565A;
  font-weight: 500;
  line-height: 22px;
  margin-left: 1.25rem;
  margin-bottom: 0;
}

.home-projects .bc-button {
  max-width: unset !important;
}

.mainpage-links {
  justify-content: space-between;
}
.mainpage-links .mainpage-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
}
.mainpage-links p {
  font-family: "Roboto Condensed";
}

@media (min-width: 992px) {
  .home-stats .block-content {
    flex-wrap: nowrap;
  }
}
@media (max-width: 550px) {
  .mainpage-links img {
    max-width: 100px;
  }
  .mainpage-links p {
    font-size: 9px !important;
    margin-bottom: 0 !important;
    line-height: unset !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .mainpage-links img {
    height: 95%;
    min-width: 75px;
  }
}
.project-item {
  transition: all 0.5s;
}
.project-item:hover {
  transform: scale(1.01) !important;
}
.project-item .proj-pic {
  width: 100%;
  border-radius: 20px;
  height: 350px;
}
.project-item .img {
  display: block;
  max-height: 350px;
  border-radius: 20px;
}
.project-item p {
  color: #4E565A;
  font-weight: 500;
}
.project-item div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.project-item small {
  display: flex;
  align-items: center;
  color: #4E565A;
  font-weight: 500;
  font-size: 14px;
}

.project-details p,
.project-details li {
  letter-spacing: 0.02em;
  color: #4E565A;
  line-height: 2rem;
}
.project-details .apartment-plan .apt-label {
  letter-spacing: 0.02em;
  color: #ffffff;
  background: #223A5E;
  border-radius: 8px;
  padding: 0.3rem 2.2rem;
  width: -moz-max-content;
  width: max-content;
}
.project-details .apartment-plan .plan-heading {
  font-weight: 400;
  color: #223A5E;
  letter-spacing: 0.02em;
}
.project-details .apartment-plan small {
  margin-bottom: 0.5rem;
  letter-spacing: 0.02em;
  color: #4E565A;
}
.project-details .apartment-plan small b {
  font-weight: 500;
}
.project-details .h {
  letter-spacing: 0.06em;
}
.project-details .accordion p,
.project-details .accordion button {
  color: #4E565A;
}

.single-project .breadcrumb {
  height: 0;
  margin-bottom: 0 !important;
}
.single-project .project-banner {
  overflow: hidden;
  background-position: 10%;
  background-repeat: no-repeat;
  background-size: 100%;
}
.single-project #tab1 {
  background-color: #223a5e;
}
.single-project #tab2 {
  background-color: #4b668e;
}
.single-project #tab3 {
  background-color: #7b97c0;
}
.single-project .nav-tabs .nav-link {
  margin-right: unset;
  color: white !important;
  font-size: 1rem;
}
.single-project .nav-tabs .nav-link.active {
  border-bottom: unset;
}
.single-project .buttons-2 a {
  width: -moz-max-content;
  width: max-content;
}
.single-project .buttons-2 a button {
  text-transform: uppercase;
  background: #8ca2c2;
  border-radius: 40px;
  padding: 1rem 50px;
  border: none;
  font-weight: 500;
  color: #223a5e;
  font-size: 12px;
  line-height: 14px;
  width: -moz-max-content;
  width: max-content;
}
.single-project .buttons-2 a button:hover {
  background-color: #4b6183;
  color: white;
  transition: 0.4s;
}
.single-project .room-details div p {
  font-size: 14px;
  color: #7b97c0;
}
.single-project .multimedia-tab {
  background: #223a5e;
  border-radius: 22px;
  display: block;
  cursor: pointer;
  width: -moz-max-content;
  width: max-content;
}
.single-project .multimedia-tab a {
  color: white;
  font-weight: 500;
  font-size: 1rem;
}
.single-project .multimedia-tab.active {
  background: #8ca2c2;
}
.single-project .multimedia-btn {
  background-color: white;
  transform: translateY(-125%);
  text-align: center;
  text-transform: capitalize;
  color: #223a5e;
  padding: 0.5rem 1rem;
  width: 31%;
  font-weight: 500;
  border-radius: 22px;
  font-size: 10px;
  border: none;
  margin-left: 1rem;
  cursor: pointer;
}
.single-project .multimedia-info {
  position: absolute;
  top: 5%;
  padding: 0.8rem;
  left: 10%;
  width: 530px;
  height: 300px;
  background-color: white;
}
.single-project .multimedia-info small,
.single-project .multimedia-info li {
  font-size: 13px;
  color: #223A5E;
}
.single-project .ql-editor p,
.single-project .ql-editor span {
  font-size: unset;
  color: black !important;
}

.proj-buttons .dropdown button {
  font-family: Arial, Helvetica, sans-serif !important;
}
.proj-buttons .dropdown .dropdown-menu,
.proj-buttons .dropdown button {
  background: #f5f5f7;
  border-radius: 8px;
  border: 1px solid #545252;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.proj-buttons .dropdown button:active {
  background-color: #f5f5f7;
  color: #4E565A;
}
.proj-buttons .dropdown button,
.proj-buttons .dropdown .dropdown-item {
  color: #4E565A;
  font-weight: 700;
  cursor: pointer;
}
.proj-buttons .dropdown .dropdown-menu::before {
  border-bottom: unset;
}
.proj-buttons .dropdown .dropdown-item:hover {
  background: transparent !important;
  color: #4E565A !important;
}

.project-check input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  background-color: #fff;
  margin-left: auto;
}
.project-check input[type=checkbox] + label {
  width: 170px;
  display: block;
  cursor: pointer;
  padding: 0.3rem 1rem;
}
.project-check input[type=checkbox]:checked + label {
  background: #223a5e;
}
.project-check input[type=checkbox] + label,
.project-check .input-select,
.project-check .input-select:focus {
  outline: none;
  box-shadow: none;
  background: #8ca2c2;
  border: 1px solid #223a5e;
  border-radius: 22px;
  color: white;
  font-size: 14px;
  font-weight: 700;
}
.project-check .input-select:after {
  content: "\f078";
}
.project-check .input-select,
.project-check .input-select:focus {
  width: 85px;
}

@media (min-width: 992px) {
  .project-description {
    -moz-column-count: 3;
         column-count: 3;
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }
}
@media (max-width: 999px) {
  .project-item .proj-pic,
  .project-item .img,
  .project-item .img img {
    height: 230px !important;
  }
}
#multimedia .swiper-pagination {
  text-align: end !important;
}
#multimedia .swiper-pagination-bullet {
  width: 24px !important;
  height: 3px !important;
  border-radius: 11px !important;
  background: #e9e7e7 !important;
}
#multimedia .swiper-pagination-bullet-active {
  background: white !important;
}
#multimedia .swiper-slide {
  filter: blur(4px);
}
#multimedia .swiper-slide-active {
  filter: blur(0);
}

.bottom-10 {
  bottom: 10%;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

.modal-dialog {
  margin: 0 !important;
  position: fixed;
  transform: translate(1rem, 10rem) !important;
}

.ql-align-center {
  text-align: center;
}

#projects-map {
  pointer-events: all;
}
#projects-map .proj-buttons {
  z-index: 15;
}
#projects-map .region-selector {
  border: 1px solid #223a5e;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
  color: #5b5b5b;
  font-weight: 700;
  border-radius: 8px;
  line-height: 1.5rem;
  padding: 0.5rem;
  outline: none !important;
}
#projects-map .mapinfo-popup {
  background-color: rgba(34, 58, 94, 0.8);
  color: white;
  font-size: 700;
  padding: 1.5rem;
  position: absolute;
  right: 3%;
  top: 20%;
  max-height: 500px;
  overflow: auto;
}
#projects-map .mapinfo-popup p {
  line-height: 1.7rem;
}

@media only screen and (max-width: 1100px) and (orientation: landscape) {
  .single-project .project-portal {
    height: 110vh !important;
  }
  .single-project .project-portal * {
    font-size: 0.6rem !important;
  }
  .single-project .project-portal .modal-title {
    font-size: 0.9rem !important;
  }
  .single-project .apt-table-wrap {
    right: -10px !important;
    width: 72% !important;
    padding: 0px 17px !important;
  }
}
.gms-banner {
  background-image: url("../../../assets/img/guzestli menzil/banner1.svg");
  min-height: 320px;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Roboto Condensed" !important;
}

.kom-banner {
  background-image: url("../../../assets/img/guzestli menzil/banner2.svg");
  min-height: 320px;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Roboto Condensed" !important;
}

.accordion-item {
  border: none !important;
}
.accordion-item .accordion-button::after {
  display: none;
}
.accordion-item .accordion-button .acc-btn-content {
  min-height: 110px;
  border-radius: 0px 10px 10px 0px;
}
.accordion-item .accordion-button .acc-btn-content h4 {
  font-weight: 400;
  color: white;
  font-size: 2rem;
  line-height: 38px;
}

.gms-page .accordion-button.collapsed {
  transition: 0.4s;
}
.gms-page .accordion-button.collapsed:hover {
  transform: scale(1.02) !important;
}

.accordion2 .accordion-header2 {
  background: #76859b;
  border-radius: 5px;
  text-align: center;
}
.accordion2 .accordion-header2 .accordion-button2 {
  width: 100%;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 1.125rem;
}
.accordion2 .accordion-header2 .accordion-button2::after {
  display: none;
}
.accordion2 .accordion-collapse2 {
  background: #ffffff;
  border: 1px solid #bbb7b7;
  border-radius: 5px;
  padding: 14px 20px;
  margin: 10px 0;
}
.accordion2 .accordion-collapse2 .accordion-body2 {
  color: #223A5E;
}

#kom-label {
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #223a5e;
  margin-bottom: 0;
}

.questionnable {
  cursor: help;
  border-bottom: 1px solid;
  width: -moz-max-content;
  width: max-content;
}

.announcements-item .announcement-content img {
  width: 100%;
}
.announcements-item h5 {
  font-weight: 400 !important;
}
.announcements-item .small-heading {
  font-weight: 500;
  color: #4E565A;
}
.announcements-item small {
  font-weight: 400;
  color: #4E565A;
}
.announcements-item small::before {
  content: "";
  border-bottom: 4px solid #223A5E;
  padding-left: 5.8rem;
  color: white;
  border-radius: 5px;
  position: absolute;
  bottom: 30px;
}

.announcement-page .announcement-content img {
  width: 100% !important;
  max-width: 100vw !important;
}
.announcement-page h2 {
  font-weight: 500;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  color: #223a5e;
  font-family: "Roboto Condensed";
}
.announcement-page h5 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
  color: #223a5e;
}
.announcement-page .anns-link {
  background: #8ca2c2;
  border-radius: 5px;
}
.announcement-page .anns-link h6 {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
}

@media (min-width: 798px) {
  .anns-link {
    width: calc((100% - 16px) / 3) !important;
  }
}
.coop-item h5 {
  color: #4E565A;
  font-weight: 700;
}
.coop-item .date {
  flex-wrap: wrap;
  width: 10%;
  font-style: italic;
}
.coop-item .date::before {
  content: "";
  border-bottom: 4px solid #223A5E;
  padding-left: 5.8rem;
  color: white;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.news-info p,
.news-info h5,
.news-info h6 {
  color: #4E565A;
}
.news-info p {
  font-weight: 700;
}
.news-info h5 {
  line-height: 1.3rem;
}
.news-info small {
  font-weight: 400;
  color: rgba(78, 86, 90, 0.7);
  display: flex;
  align-items: center;
}

.news-page p {
  margin: 1.5rem 0;
}
.news-page h4,
.news-page h6 {
  color: #4E565A;
}
.news-page h4 {
  font-weight: 700;
}
.news-page .date {
  font-size: 15px;
}

.news-item .news-date {
  background: rgba(34, 58, 94, 0.8);
  color: #FFFFFF;
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: -moz-max-content;
  width: max-content;
}
.news-item .news-title {
  font-weight: 500;
  color: #223A5E;
}

.news-item2 {
  min-height: 450px;
}

#news-page .news-item .news-date {
  font-size: 16px;
}
#news-page .news-item .news-title {
  font-size: 16px;
  line-height: 19.2px;
}

@media (min-width: 992px) {
  .news-main-pic {
    width: -moz-max-content;
    width: max-content;
  }
}
#news-home .news-item .news-date {
  font-size: 12px;
}
#news-home .news-item .news-title {
  font-size: 14px;
  line-height: 16.8px;
}

.multimedia {
  background-color: #142237;
}
.multimedia .big-link {
  font-weight: 400;
  font-family: "Roboto Condensed";
  font-size: 60px;
  line-height: 70px;
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  text-align: center;
  border-bottom: 1px solid white;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.multimedia .gallery-horizontal .swiper-pagination-fraction {
  bottom: 0px;
  left: 0;
  font-size: 22px;
  line-height: 26px;
  height: 50px;
  width: 100%;
  color: white;
  display: flex;
  background: rgba(140, 162, 194, 0.8);
  justify-content: center;
  align-items: center;
}
.multimedia .gallery-horizontal .swiper-main {
  border-radius: 0;
}
.multimedia .gallery-horizontal .swiper-main img {
  border-radius: 0;
}
.multimedia .gallery-horizontal .swiper-slide {
  border-radius: 0;
}
.multimedia .gallery-horizontal .swiper-slide img {
  border-radius: 0;
}

@media (max-width: 1000px) {
  .multimedia .big-link {
    font-size: 30px;
    padding-bottom: 2px;
  }
  .multimedia-link div {
    justify-content: center !important;
  }
  .multimedia-link:last-of-type {
    flex-direction: column-reverse;
  }
}
#one-news-content a {
  color: rgb(10, 88, 202) !important;
  text-decoration: underline !important;
}

.image-holder {
  color: #103540;
  text-align: start;
}
.image-holder h6 {
  font-size: 18px;
  line-height: 22px;
}
.image-holder small {
  font-size: 16px;
  line-height: 19px;
  font-style: italic;
}

.text-inf {
  text-align: justify;
  margin-bottom: 0;
  line-height: 36px;
  color: #4E565A;
  font-size: 18px;
}

.text-inf p {
  text-align: justify;
  margin-bottom: 0;
  color: #4E565A;
  line-height: 36px;
}

.audit-info .audit-card {
  display: flex;
  background-color: rgba(34, 58, 94, 0.9);
  min-height: 230px !important;
}
.audit-info .audit-card .audit-symbol {
  transform: translateY(25%) translateX(15%);
}
.audit-info .audit-card p {
  font-weight: 700;
  font-size: 1rem;
  line-height: 19px;
  color: #103540;
}

.bg-picture1 {
  background-image: url("../../../assets/img/bg/hesabat-bg1.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-picture3 {
  background-image: url("../../../assets/img/bg/hesabat-bg3.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.audit-page .bg-picture2 {
  background-image: url("../../../assets/img/bg/hesabat-bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.audit-page .audit-card {
  height: 600px;
  background-color: rgba(34, 58, 94, 0.9) !important;
}
.audit-page .audit-card_side {
  transition: all 0.8s ease;
  /* We don't want to see the back part of the element. */
  backface-visibility: hidden;
  /* We don't want to see the back part of the element. */
  border-radius: 3px;
}
.audit-page .audit-card_side .line {
  width: 145px;
  background-color: white;
  height: 1px;
}
.audit-page .audit-card_side_back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.audit-page .audit-card_side_back button {
  background: rgba(34, 58, 94, 0.8);
  border: 2px solid #ffffff;
  border-radius: 20px;
  color: #FFFFFF;
  font-style: italic;
}
.audit-page .audit-card_side_front h2 {
  font-size: 128px;
  line-height: 154px;
  color: rgba(255, 255, 255, 0.5);
}

.mida-mmc .bg-picture2 {
  background-image: url("../../../assets/img/bg/hesabat-bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.mida-mmc .audit-card {
  height: 400px;
  background: rgba(140, 162, 194, 0.9);
}
.mida-mmc .audit-card_side {
  transition: all 0.8s ease;
  /* We don't want to see the back part of the element. */
  backface-visibility: hidden;
  /* We don't want to see the back part of the element. */
  border-radius: 3px;
}
.mida-mmc .audit-card_side .line {
  width: 145px;
  background-color: white;
  height: 1px;
}
.mida-mmc .audit-card_side_back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.mida-mmc .audit-card_side_back button {
  background: #223a5e;
  border: 2px solid #ffffff;
  border-radius: 20px;
  color: #FFFFFF;
  font-style: italic;
}
.mida-mmc .audit-card_side_front h2 {
  font-size: 6rem;
  line-height: 6.5rem;
  color: #223A5E;
}
.mida-mmc form label {
  margin-left: 3px;
  color: #4E565A;
  margin-bottom: 0.1rem;
}
.mida-mmc form #send-btn {
  font-size: 12px;
  margin-left: auto;
  padding: 6px 15px !important;
  border-radius: 5px !important;
}

.custom-pagination .custom-bullet {
  opacity: 1;
  background: #223A5E !important;
  font-size: 1rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 12px;
  text-align: center;
  border-radius: 6px;
}
.custom-pagination .custom-bullet.swiper-pagination-bullet-active {
  background-color: #F8851B !important;
}

.audit-card {
  position: relative;
  perspective: 200rem;
}
.audit-card_side {
  transition: all 0.8s ease;
  /* We don't want to see the back part of the element. */
  backface-visibility: hidden;
  /* We don't want to see the back part of the element. */
  border-radius: 3px;
}
.audit-card_side .line {
  width: 145px;
  background-color: white;
  height: 1px;
}
.audit-card_side_back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.audit-card_side_back button {
  border: 2px solid #ffffff;
  border-radius: 20px;
  font-style: italic;
}
.audit-card_side_front {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
}
.audit-card_side_front h2 {
  transform: rotate(-90deg);
  font-weight: 500;
}
.audit-card:hover .audit-card_side_back {
  transform: rotateY(0);
}
.audit-card:hover .audit-card_side_front {
  transform: rotateY(-180deg);
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
  /* add perspective to container */
}

/* flip the inner div on hover */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
  transition: all 0.9s;
}

/* style the front and back sides of the card */
.flip-card-front,
.flip-card-back {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* hide the back side of the card */
}

/* style the front side of the card */
.flip-card-front {
  position: absolute;
  transform: rotateY(0deg);
}

/* style the back side of the card */
.flip-card-back {
  transform: rotateY(180deg);
}

/* add transitions for smooth flipping */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.mmc_nizamname_link:hover {
  color: inherit;
  text-decoration: underline !important;
}

.sales-page-heading div {
  color: #FFFFFF;
  padding: 0 3rem;
  text-align: center;
}

@media (max-width: 700px) {
  .text-inf {
    font-size: 0.9rem;
  }
  .text-inf h6 {
    font-size: 20px;
  }
  .text-inf p {
    font-size: 0.9rem;
    line-height: 1.7rem;
  }
  #nizamname .txt-nz {
    text-align: start;
    font-size: 1.1rem;
    line-height: 28px;
  }
  .management-page h2 {
    font-size: 22px;
  }
  .management-page h5 {
    font-size: 20px;
  }
  .sales-page-heading {
    justify-content: center;
  }
  .sales-page-heading div:first-child {
    font-size: 1.5rem !important;
    border-right: none !important;
  }
  .sales-page-heading div:nth-child(2) {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}
.constitution-page .card p {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #103540;
}
.constitution-page .card small {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #223A5E;
}
.constitution-page table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}
.constitution-page table thead {
  background-color: #8ca2c2;
  color: white;
}
.constitution-page table thead th {
  padding: 24px 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  vertical-align: middle;
  text-align: center;
}
.constitution-page table tbody tr {
  background-color: white;
}
.constitution-page table tbody td {
  text-align: center;
  vertical-align: middle;
  padding: 24px 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #103540 !important;
}
.constitution-page table tbody td a {
  text-decoration: underline;
  color: #103540 !important;
  vertical-align: middle;
}

@media (max-width: 1080px) {
  .constitution-page table thead th {
    padding: 20px 0;
    font-size: 20px;
    line-height: 24px;
  }
  .constitution-page table tbody td {
    padding: 20px 0;
    font-size: 18px;
    line-height: 22px;
  }
}
@media (max-width: 500px) {
  .constitution-page table thead th {
    padding: 18px 0;
    font-size: 0.9rem;
    line-height: 22px;
  }
  .constitution-page table tbody td {
    padding: 18px 3px;
    font-size: 0.6rem;
    line-height: 20px;
  }
  .constitution-page table img {
    width: 15px;
    height: 15px;
  }
}
.contact-heading-text {
  background: #8ca2c2;
}
.contact-heading-text h5 {
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
}

.contact-page input,
.contact-page textarea {
  background: #ffffff;
  border: 1px solid rgba(78, 86, 90, 0.5);
  border-radius: 10px;
}
.contact-page input:focus,
.contact-page textarea:focus {
  outline: none;
  box-shadow: none;
}
.contact-page input::-moz-placeholder, .contact-page textarea::-moz-placeholder {
  font-size: 14px;
  line-height: 20px;
  padding-left: 0.7rem;
  color: rgba(78, 86, 90, 0.4);
}
.contact-page input::placeholder,
.contact-page textarea::placeholder {
  font-size: 14px;
  line-height: 20px;
  padding-left: 0.7rem;
  color: rgba(78, 86, 90, 0.4);
}
.contact-page label {
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
  color: #4E565A;
}
.contact-page #send-btn {
  width: 48%;
}
.contact-page .accordion-item .accordion-header {
  background: #20385d !important;
  padding: 12px 24px;
}
.contact-page .accordion-item .accordion-header button {
  color: white;
}
.contact-page .accordion-item .accordion-button::after {
  background-image: url("../../img/utils/plus.svg") !important;
}
.contact-page .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("../../img/utils/minus.svg") !important;
}
.contact-page .accordion-item .accordion-button {
  font-weight: 500;
  color: #4E565A;
  font-weight: 1.375rem;
  padding: 0 !important;
}
.contact-page .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.contact-page .accordion-item .accordion-button:not(.collapsed) {
  background: #20385d;
}
.contact-page .accordion-item .accordion-body .accordion-body-text {
  background-color: white !important;
}
.contact-page .accordion-item .accordion-collapse small {
  color: #4E565A;
  line-height: 2rem !important;
}

.contact-details .contact-item {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.contact-details .contact-item label {
  font-size: 1rem;
  font-weight: 500;
  line-height: 19px;
  color: #4E565A;
}
.contact-details .contact-item p {
  width: 100%;
  font-size: 1rem;
  line-height: 19px;
  color: #4E565A;
}

.arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 400;
  font-size: 18px;
  background: #8ca2c2 !important;
  width: 200px;
  height: 50px;
}

.arrow:after,
.arrow:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow:after {
  border-left-color: #8ca2c2;
  border-left-width: 40px !important;
  border-width: 25px;
  margin-top: -25px;
}

.metbuat-card {
  background: #223A5E;
  box-shadow: 5px 5px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  color: white;
}
.metbuat-card h2 {
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .contact-page iframe {
    height: 400px;
  }
}
@media (min-width: 1000px) {
  .contact-page .accordion-body .accordion-body-text {
    margin-left: 60px !important;
  }
}
@media (max-width: 1000px) {
  .contact-item {
    flex-wrap: wrap;
  }
  .metbuat-card .content {
    transform: translateY(0%);
    padding: 0.5rem !important;
  }
  .metbuat-card .content div {
    width: 100% !important;
    border: none !important;
    justify-content: center;
    padding: 1rem !important;
  }
  .metbuat-card .content div p {
    margin: 0.5rem 0 !important;
  }
  .contact-page .accordion-header button {
    font-size: 1rem;
  }
  .contact-page .contact-heading-text h5 {
    font-size: 18px;
  }
}
@media (max-width: 600px) {
  .contact-page .accordion-header button {
    font-size: 0.8rem;
  }
  .contact-page .contact-heading-text h5 {
    font-size: 1rem;
  }
}/*# sourceMappingURL=style.css.map */