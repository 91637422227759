.medium {
    h1 {
        font-size: 3.75rem;
        line-height: 6.75rem;
    }

    h5 {
        font-size: 1.875rem;
    }

    h6,
    small,
    .footer-heading-link {
        font-size: 1.5rem;
        line-height: 1.575rem;
    }

    .footer-link {
        font-size: 1.5rem;
        line-height: 3rem;
    }

    p,
    .li,
    .nav-tabs .nav-link {
        font-size: 1.7rem;
    }

    p {
        line-height: 2rem;
    }

    .li,
    .nav-tabs .nav-link {
        line-height: 3rem;
    }

    .btn {
        font-size: 1.5rem;
        line-height: 2.25rem;
    }
}
