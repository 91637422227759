header {
    background-color: $bg1;
    // border-bottom: 0.5px solid rgb(78, 86, 90);

    .container-md {
        padding: 0 !important;
    }

    .invisible {
        height: 0;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s ease-in-out;
    }

    .visible {
        visibility: visible;
        opacity: 1;
        transition: visibility 0.5s, opacity 0.5s ease-out;
    }

    // @keyframes navbar {
    //     0% {
    //         opacity: 0;
    //     }
    //     50% {
    //         opacity: 0.5;
    //     }
    //     100% {
    //         opacity: 1;
    //     }
    // }

    nav {
        .nav-menu {
            display: flex;

            a {
                color: $text-color5;
                // font-weight: 500;
                letter-spacing: 0.02em;
                font-size: 1.25rem;
            }

            & > div:first-child {
                margin-left: -1rem;
            }
        }

        .menu-buttons-desktop {
            display: flex;
        }

        .dropdown-menu {
            background: $text-color5;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            // border-radius: 0 12px 12px 12px;
            min-width: 250px !important;
            border: none;
            border-radius: 0 !important;
          

            a {
                font-size: 1rem;
                color: white;  

            }
        }
    }

    .libraries {
        a {
            color: $text-color5;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .nav-menu-mobile,
    .nav-menu-mobile-icon {
        display: none;
    }

    .nav-menu-mobile-icon {
        color: $text-color1;
        font-size: 2rem;
    }

    .nav-menu-mobile {
        height: max-content;
        transition: display 1s linear;
        // background-color: $text-color5;
        // color: white;

        .navmobile-heading-link {
            // font-weight: 600;
            font-weight: 700;
            color: $text-color5;
            font-size: 1.5rem;
            text-decoration: underline;
        }

        .navmobile-link {
            font-weight: 400;
            color: $text-color5;
            font-size: 1rem!important;
        }
        // @media(max-width: 700px) {
        //     .navmobile-link {
        //         font-size: 1.2rem!important;
        //     }
        // }
    }

    .mob-show {
        display: flex !important;
    }

    .search {
        display: flex;
        align-items: center;
        color: $highlight;
    }

    .languages-desktop {
        a {
            color: $text-color5;
            margin-left: 30px;
        }

        .lang-active {
            font-weight: 500;
        }
    }

    .social-links {
        img {
            width: 24px;
            height: 24px;
        }
    }
}

/* Hamburger Menu */
.menu-icon {
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;

    .menu-icon__cheeckbox {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;
        z-index: 2;
        -webkit-touch-callout: none;
        position: absolute;
        opacity: 0;
    }
    div {
        margin: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 22px;
        height: 12px;
    }
    span {
        position: absolute;
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--bar-bg, #000);
        border-radius: 1px;
        transition: all 0.2s cubic-bezier(0.1, 0.82, 0.76, 0.965);

        &:first-of-type {
            top: 0;
        }
        &:last-of-type {
            bottom: 0;
        }
    }
    &.active,
    .menu-icon__cheeckbox:checked + div {
        span {
            &:first-of-type {
                transform: rotate(45deg);
                top: 5px;
            }
            &:last-of-type {
                transform: rotate(-45deg);
                bottom: 5px;
            }
        }
    }

    &.active:hover span:first-of-type,
    &.active:hover span:last-of-type,
    &:hover .menu-icon__cheeckbox:checked + div span:first-of-type,
    &:hover .menu-icon__cheeckbox:checked + div span:last-of-type {
        width: 22px;
    }

    &:hover {
        // no need hover effect on mobile.
        @media (min-width: 1024px) {
            span:first-of-type {
                width: 26px;
            }

            span:last-of-type {
                width: 12px;
            }
        }
    }
}

.dropdown-toggle::after {
    border: none !important;
}

.dropdown-toggle {
    font-family: "Roboto Condensed", sans-serif !important;
}

// .dropdown-menu::before {
//     content: "";
//     border-bottom: 2px solid white;
//     margin-left: 1.2rem;
//     padding-left: 1.8rem;
//     color: white;
//     border-radius: 5px;
//     position: absolute;
//     top: 8px;
// }

.dropdown-menu {
    display: none;
    width: 210px;
    // max-width: 240px;
    // min-width: 187px !important;
    width: max-content;
    left: 0;
}

.dropdown-item {
    white-space: unset !important;
    width: 100%;
    padding: 0 1.2rem !important;
    margin: 0.5rem 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 19px;
    transition: 0.3s;

    &:hover {
        padding-left: 1.4rem !important;
        background: transparent !important;
        color: white !important;
        text-decoration: underline !important;
    }

    &:focus,
    &:active {
        background-color: transparent !important;
    }
}

.dropdown-hover-btn {
    width: max-content;
    padding: 0.2rem 1rem;

    &.active {
        background: $text-color5;
        a {
            color: white !important;
        }
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // border-radius: 8px !important;
    }
}

.dropdown-hover-btn:hover {
    .dropdown-menu,
    .dropdown-menu:hover {
        display: block !important;
    }

    background: $text-color5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // border-radius: 8px 8px 0px 0px;

    a {
        color: white;
    }
}

.search-mob-holder {
    border: 1px solid $highlight;

    .search-mob {
        border: none;
        height: 60px;
        border-radius: 0;
        outline: none;
        font-size: 1.2rem;
        color: $highlight;

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            color: $highlight;
            font-size: 1.2rem;
        }
    }
}

.languages-desktop,
.libraries {
    a {
        font-family: "Roboto Condensed" !important;
        font-weight: 300;
    }
}

.languages-mobile {
    a {
        color: $text-color5;
    }
}

@media (max-width: 992px) {
    header {
        border-bottom: 1px solid lightgrey;
    }

    .nav-menu,
    .search-desktop,
    .languages-desktop,
    .social-links-desktop,
    .libraries-desktop,
    .menu-buttons-desktop,
    .menu-shrinked-desktop,
    .logo-text {
        display: none !important;
    }

    .nav-menu-mobile-icon,
    .search-mobile {
        display: block !important;
    }
}

@media (max-width: 792px) {
    header {
        height: auto;
    }

    nav {
        .logo-text {
            width: 70%;
        }
    }
}
