.contact-heading-text {
    background: #8ca2c2;

    h5 {
        font-weight: 500;
        font-size: 20px;
        color: $text-color4;
    }
}

.contact-page {
    input,
    textarea {
        background: #ffffff;
        border: 1px solid rgba(78, 86, 90, 0.5);
        border-radius: 10px;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &::placeholder {
            font-size: 14px;
            line-height: 20px;
            padding-left: 0.7rem;
            color: rgba(78, 86, 90, 0.4);
        }
    }

    label {
        font-size: 1rem;
        font-weight: 500;
        line-height: 19px;
        color: $text-color1;
    }

    #send-btn {
        width: 48%;
    }

    .accordion-item {
        .accordion-header {
            background: #20385d !important;
            padding: 12px 24px;

            button {
                color: white;
            }
        }

        .accordion-button::after {
            background-image: url("../../img/utils/plus.svg") !important;
        }

        .accordion-button:not(.collapsed)::after {
            background-image: url("../../img/utils/minus.svg") !important;
        }

        .accordion-button {
            font-weight: 500;
            color: $text-color1;
            font-weight: 1.375rem;
            padding: 0 !important;
            // padding-left: 0 !important;
            // padding-right: 0 !important;

            &:focus {
                box-shadow: none;
            }

            &:not(.collapsed) {
                background: #20385d;
            }
        }

        .accordion-body {
            .accordion-body-text {
                background-color: white !important;
            }
        }

        .accordion-collapse {
            small {
                color: $text-color1;
                line-height: 2rem !important;
            }
        }
    }
}

.contact-details {
    .contact-item {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        label {
            font-size: 1rem;
            font-weight: 500;
            line-height: 19px;
            color: $text-color1;
        }

        p {
            width: 100%;
            font-size: 1rem;
            line-height: 19px;
            color: $text-color1;
        }
    }
}

.arrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 400;
    font-size: 18px;
    background: #8ca2c2 !important;
    width: 200px;
    height: 50px;
}

.arrow:after,
.arrow:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow:after {
    border-left-color: #8ca2c2;
    border-left-width: 40px !important;
    border-width: 25px;
    margin-top: -25px;
}
// .arrow:before {
//     border-color: rgba(194, 225, 245, 0);
//     border-left-color: #c2e1f5;
//     border-width: 36px;
//     margin-top: -36px;
// }

.metbuat-card {
    background: $text-color5;
    box-shadow: 5px 5px 4px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    // display: flex;
    // align-items: flex-end;
    color: white;
    h2 {
        text-transform: uppercase;
    }

    // .content {
    //     // transform: translateY(-30%);
    // }
}

@media (min-width: 576px) {
    .contact-page {
        iframe {
            height: 400px;
        }
}
}
@media (min-width: 1000px) {
    .contact-page {
        .accordion-body {
            .accordion-body-text {
                margin-left: 60px !important;
            }
        }
    }
}

@media (max-width: 1000px) {
    .contact-item {
        flex-wrap: wrap;
    }

    .metbuat-card {
        .content {
            transform: translateY(0%);
            padding: 0.5rem !important;
            div {
                width: 100% !important;
                border: none !important;
                justify-content: center;
                padding: 1rem !important;

                p {
                    margin: 0.5rem 0 !important;
                }
            }
        }
    }

    .contact-page {
        .accordion-header {
            button {
                font-size: 1rem;
            }
        }

        .contact-heading-text {
            h5 {
                font-size: 18px;
            }
        }
    }
}


@media (max-width: 600px){
    .contact-page {
        .accordion-header {
            button {
                font-size: 0.8rem;
            }
        }

        .contact-heading-text {
            h5 {
                font-size: 1rem;
            }
        }
    }
}