.large {
    h1 {
        font-size: 5rem;
        line-height: 9rem;
    }

    h5 {
        font-size: 2.5rem;
    }

    h6,
    small,
    .footer-heading-link {
        font-size: 2rem; // 32px
        line-height: 2.1rem; // 38px
    }

    .footer-link {
        font-size: 3rem;
        line-height: 4rem;
    }

    p,
    .li,
    .nav-tabs .nav-link {
        font-size: 2.25rem; // 36px
    }

    p {
        line-height: 2.75rem; // 22px
    }

    .li,
    .nav-tabs .nav-link {
        line-height: 4rem;
    }

    .btn {
        font-size: 2rem;
        line-height: 3rem; // 24px
    }
}
