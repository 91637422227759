.gms-banner {
    background-image: url("../../../assets/img/guzestli\ menzil/banner1.svg");
    min-height: 320px;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Roboto Condensed" !important;
}

.kom-banner {
    background-image: url("../../../assets/img/guzestli\ menzil/banner2.svg");
    min-height: 320px;
    background-repeat: no-repeat;
    background-size: cover;
    font-family: "Roboto Condensed" !important;
}

.accordion-item {
    border: none !important;

    .accordion-button {
        &::after {
            display: none;
        }

        .acc-btn-content {
            min-height: 110px;
            // padding: 1rem 0;
            border-radius: 0px 10px 10px 0px;

            h4 {
                font-weight: 400;
                color: white;
                font-size: 2rem;
                line-height: 38px;
            }
        }
    }
}
.gms-page {
    .accordion-button.collapsed {
        transition: 0.4s;

        &:hover {
            transform: scale(1.02) !important;
        }
    }
}

.accordion2 {
    .accordion-header2 {
        background: #76859b;
        border-radius: 5px;
        text-align: center;

        .accordion-button2 {
            width: 100%;
            outline: none;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            &::after {
                display: none;
            }

            color: white;

            font-size: 1.125rem;
        }
    }

    .accordion-collapse2 {
        background: #ffffff;
        border: 1px solid #bbb7b7;
        border-radius: 5px;
        padding: 14px 20px;
        margin: 10px 0;

        .accordion-body2 {
            color: $text-color5;
        }
    }
}

#kom-label {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #223a5e;
    margin-bottom: 0;
}

.questionnable {
    cursor: help;
    border-bottom: 1px solid;
    width: max-content;
}
