.more-info-btn {
    border-radius: 50px;
    background-color: $text-color5;
    color: white !important;
    font-weight: 700;
    padding: 10px 10px 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.5s;

    small {
        font-size: 1rem !important;
        line-height: 24px !important;
    }

    &:hover {
        transform: scale(1.03);
    }
}

.btn-search {
    background-color: #223a5e;
    display: flex;
    align-items: center;
    border-radius: 50px;
    border: none;
    color: white;
    padding: 0.8rem;
}

// button transparent
.button-tp {
    border: 1px solid $text-color1;
    border-radius: 4px;
    background-color: transparent;
    font-weight: 500;
    font-size: 16px;
    text-align: justify;
    color: $text-color1;
    padding: 0.5rem 1rem;
}

.btn-custom {
    background: $text-color5 !important;
    border-radius: 8px !important;
    color: $text-color4 !important;
    letter-spacing: 0.04em !important;

    &:hover {
        color: white !important;
        background: #192942 !important;
    }

    &:active {
        color: white !important;
        background: #192942 !important;
    }
}

.btn-show-more {
    background: #8ca2c2;
    border-radius: 50px;
    color: white;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 1.8rem;
    border: none;

    &:hover {
        background: #223a5e;
    }
}

.btn-share {
    padding: 0.1rem 0.7rem 0.1rem 0;
    color: white !important;
    background: $text-color5;
    border-radius: 20px;
    cursor: pointer;

    img {
        margin-right: 0.5rem;
    }

    &:hover {
        color: white;
        text-decoration: underline !important;
    }
}

.btn-gms {
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #76859b;
    border-radius: 5px;
    display: flex;
    align-items: center;
    min-width: 230px !important;

    small {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #76859b;
    }
}

.home-main-btn {
    background-color: #223a5e;
    opacity: 0.8;
    width: 100%;
    height: 160px !important;
    padding: 1rem 0;
    border-radius: 12px;

    img {
        height: 100%;
        padding: 0;
        margin: auto 0;
    }

    &:hover {
        opacity: 1;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .home-main-btn {
        height: 130px !important;
    }
}

@media (max-width: 768px) and (max-width: 992px) {
    .mainpage-link {
        padding: 0 0 !important;
        // height: 50%;

        p {
            font-size: 0.8rem;
        }
    }

    .home-main-btn {
        height: 125px !important;

        img {
            // width: 100%;
            // height: 100%;
            padding: 0;
            margin: auto 0;
        }
    }
}

@media (max-width: 767px) {
    .home-main-btn {
        height: 100% !important;
    }
}

@media (max-width: 600px) {
    .home-main-btn {
        height: unset !important;
    }
}

@media (max-width: 1100px) {

    .more-info-btn,
    .btn-show-more {
        transform: scale(0.8);
    }

    .btn-container {
        justify-content: center !important;
    }
}