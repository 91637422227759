small,
.footer-heading-link,
.small-heading {
    font-size: 1rem; // 16px
    line-height: 1.05rem; // 19px
}

.footer-link {
    font-size: 1rem;
    line-height: 2rem;
}

p,
.li,
.nav-tabs .nav-link {
    font-size: 1.125rem; // 18px
}

p {
    line-height: 1.375rem; // 22px
}

.li,
.nav-tabs .nav-link {
    line-height: 2rem;
}

.btn {
    font-size: 1rem;
    line-height: 1.5rem; // 24px
}

.h4 {
    font-size: 1.375rem;
}

.page-link,
h5 {
    font-size: 1.25rem; // 20px
    line-height: 1.75rem; // 28px
}

h6 {
    font-size: 1.125rem;
}

.small {
    font-weight: 500 !important;
    color: $text-color1 !important;
}

@media (max-width: 700px) {
    .section-heading {
        font-size: 1.5rem !important;
    }

    .page-link,
    h5, h4 {
        font-size: 1rem !important;
        line-height: 1.4rem !important;
    }

    h6 {
        font-size: 0.9rem !important;
    }

    p,
    .li,
    .nav-tabs .nav-link {
        font-size: 0.9rem !important;
    }

    p {
        line-height: 1.1rem !important;
    }

    .navmobile-link {
        font-size: 1.2rem!important;
    }
}
