.slider {
	// height: calc(80vh - 128px);
	width: 100%;
	position: relative;
	aspect-ratio: auto 3.3 / 1;

	.swiper-slide-btn {
		top: 50% !important;
		transform: translateY(-50%) !important;
	}

	.swiper {
		overflow-x: hidden !important;
		overflow-y: visible !important;
		position: static !important;
	}

	.swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;

		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;

		.slider-text {
			margin-bottom: 1.7rem;
			background: rgba(34, 58, 94, 0.8);

			small {
				display: flex;
				text-align: start;
				color: $text-color4;
			}
		}
	}

	.swiper-horizontal > .swiper-pagination-bullets,
	.swiper-pagination-bullets.swiper-pagination-horizontal {
		bottom: unset !important;
		margin-top: -1.4rem;
	}

	.swiper-pagination-bullet {
		width: 16px;
		height: 16px;
		opacity: 1;
		margin: 0 6px !important;
		background: #d9d9d9 !important;

		&-active {
			// background: #32c3eb !important;
			background: $text-color5 !important;
		}
	}

	.swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}

	@media (max-width: 992px) {
		.swiper-slide {
			.slider-text {
				h6 {
					font-size: 17px;
					color: $text-color2;
				}
			}
		}
	}
}

@media (max-width: 992px) {
	.slider {
		height: calc(50vh - 128px);
	}
}

@media (max-width: 500px) {
	.slider {
		.swiper-pagination-bullet {
			width: 8px !important;
			height: 8px !important;
			margin: 0 6px !important;
		}

		.swiper-horizontal > .swiper-pagination-bullets,
		.slider .swiper-pagination-bullets.swiper-pagination-horizontal {
			margin-top: 0.5rem !important;
		}

		.slider-text small {
			font-size: 14px;
		}

		.swiper-slide:first-of-type img {
			object-position: left !important;
		}
	}
}
