footer {
    // background-color: $bg2;
    background-color: $text-color5;

    // .footer-text {
    //     font-weight: 600;
    //     font-size: 0.9rem;
    //     color: $text-color1;
    // }

    .footer-heading-link {
        font-weight: 500;
        letter-spacing: 0.06em;
        font-size: 1.25rem;
        font-family: "Roboto Condensed";
        // color: $text-color1;
        color: white !important;
        text-transform: uppercase;
        margin-bottom: 0.8rem;
    }

    .footer-link {
        font-weight: 400;
        letter-spacing: 0.03em;
        line-height: 2rem;
        font-size: 1rem;
        // color: $text-color1;
        color: white;
        margin: 0.1rem 0;

        &:hover {
            color: white;
            text-decoration: underline !important;
        }
    }

    small {
        font-weight: 400;
        // color: $text-color1;
        color: white;
    }

    .mt-90 {
        margin-top: 90px;
    }

    .mt-30 {
        margin-top: 30px;
    }

    .my-15 {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .mt-15 {
        margin-top: 15px;
    }
}

@media (max-width: 1000px) {
    footer {
        .footer-col {
            padding: 0.5rem !important;

            .footer-logo {
                transform: scale(0.9);
                display: none !important;
            }
        }
    }
}
