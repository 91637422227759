.project-item {
    transition: all 0.5s;

    &:hover {
        transform: scale(1.01) !important;
    }

    .proj-pic {
        width: 100%;
        border-radius: 20px;
        height: 350px;
    }

    .img {
        display: block;
        max-height: 350px;
        border-radius: 20px;
    }

    p {
        color: $text-color1;
        font-weight: 500;
    }

    div {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    small {
        display: flex;
        align-items: center;
        color: $text-color1;
        font-weight: 500;
        font-size: 14px;
    }
}

.project-details {
    p,
    li {
        letter-spacing: 0.02em;
        color: $text-color1;
        line-height: 2rem;
    }

    .apartment-plan {
        .apt-label {
            letter-spacing: 0.02em;
            color: #ffffff;
            background: $text-color6;
            border-radius: 8px;
            padding: 0.3rem 2.2rem;
            width: max-content;
        }

        .plan-heading {
            font-weight: 400;
            color: $text-color6;
            letter-spacing: 0.02em;
        }

        small {
            margin-bottom: 0.5rem;
            letter-spacing: 0.02em;
            color: $text-color1;

            b {
                font-weight: 500;
            }
        }
    }

    .h {
        letter-spacing: 0.06em;
    }

    .accordion {
        p,
        button {
            color: $text-color1;
        }
    }
}

.single-project {
    .breadcrumb {
        height: 0;
        margin-bottom: 0 !important;
    }

    .project-banner {
        overflow: hidden;
        background-position: 10%;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    #tab1 {
        background-color: #223a5e;
    }

    #tab2 {
        background-color: #4b668e;
    }

    #tab3 {
        background-color: #7b97c0;
    }

    .nav-tabs {
        .nav-link {
            margin-right: unset;
            color: white !important;
            font-size: 1rem;

            &.active {
                border-bottom: unset;
            }
        }
    }

    .buttons-2 {
        a {
            width: max-content;
            button {
                text-transform: uppercase;
                background: #8ca2c2;
                border-radius: 40px;
                padding: 1rem 50px;
                border: none;
                font-weight: 500;
                color: #223a5e;
                font-size: 12px;
                line-height: 14px;
                width: max-content;

                &:hover {
                    background-color: #4b6183;
                    color: white;
                    transition: 0.4s;
                }
            }
        }
    }

    .room-details {
        div {
            p {
                font-size: 14px;
                color: #7b97c0;
            }
        }
    }

    .multimedia-tab {
        background: #223a5e;
        border-radius: 22px;
        display: block;
        cursor: pointer;
        width: max-content;

        a {
            color: white;
            font-weight: 500;
            font-size: 1rem;
        }

        &.active {
            background: #8ca2c2;
        }
    }

    .multimedia-btn {
        background-color: white;
        transform: translateY(-125%);
        text-align: center;
        text-transform: capitalize;
        color: #223a5e;
        padding: 0.5rem 1rem;
        width: 31%;
        font-weight: 500;
        border-radius: 22px;
        font-size: 10px;
        border: none;
        margin-left: 1rem;
        cursor: pointer;
    }

    .multimedia-info {
        position: absolute;
        top: 5%;
        padding: 0.8rem;
        left: 10%;
        width: 530px;
        height: 300px;
        background-color: white;

        small,
        li {
            font-size: 13px;
            color: $text-color5;
        }
    }

    .ql-editor p,
    .ql-editor span {
        font-size: unset;
        color: black !important;
    }
}

.proj-buttons {
    .dropdown {
        button {
            font-family: Arial, Helvetica, sans-serif !important;
        }

        .dropdown-menu,
        button {
            background: #f5f5f7;
            border-radius: 8px;
            border: 1px solid #545252;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }

        button:active {
            background-color: #f5f5f7;
            color: $text-color1;
        }

        button,
        .dropdown-item {
            color: $text-color1;
            font-weight: 700;
            cursor: pointer;
        }

        .dropdown-menu::before {
            border-bottom: unset;
        }

        .dropdown-item:hover {
            // padding-left: unset !important;
            background: transparent !important;
            color: $text-color1 !important;
        }
    }
}

.project-check {
    input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        margin-left: auto;
    }

    input[type="checkbox"] + label {
        width: 170px;
        display: block;
        cursor: pointer;
        padding: 0.3rem 1rem;
    }

    input[type="checkbox"]:checked + label {
        background: #223a5e;
    }

    input[type="checkbox"] + label,
    .input-select,
    .input-select:focus {
        outline: none;
        box-shadow: none;
        background: #8ca2c2;
        border: 1px solid #223a5e;
        border-radius: 22px;

        color: white;
        font-size: 14px;
        font-weight: 700;
    }

    .input-select {
        // background-image: linear-gradient(45deg, transparent 50%, gray 50%),
        //     linear-gradient(135deg, gray 50%, transparent 50%);
        // // linear-gradient(to right, #ccc, #ccc);
        // background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
        //     calc(100% - 2.5em) 0.5em;
        // background-size: 5px 5px, 5px 5px, 1px 1.5em;
        // background-repeat: no-repeat;

        &:after {
            content: "\f078";
        }
    }

    .input-select,
    .input-select:focus {
        width: 85px;
    }
}

@media (min-width: 992px) {
    .project-description {
        column-count: 3;
        column-gap: 3rem;
    }
}

@media (max-width: 999px) {
    .project-item {
        .proj-pic,
        .img,
        .img img {
            height: 230px !important;
        }
    }
}

#multimedia {
    .swiper-pagination {
        text-align: end !important;
    }

    .swiper-pagination-bullet {
        width: 24px !important;
        height: 3px !important;
        border-radius: 11px !important;
        background: #e9e7e7 !important;
    }

    .swiper-pagination-bullet-active {
        background: white !important;
    }

    .swiper-slide {
        filter: blur(4px);
    }

    .swiper-slide-active {
        filter: blur(0);
    }
}

.bottom-10 {
    bottom: 10%;
}

.modal-backdrop.show {
    opacity: 0 !important;
}

.modal-dialog {
    margin: 0 !important;
    position: fixed;
    transform: translate(1rem, 10rem) !important;
}

.ql-align-center {
    text-align: center;
}

#projects-map {
    pointer-events: all;

    .proj-buttons {
        z-index: 15;
    }

    .region-selector {
        border: 1px solid #223a5e;
        box-shadow: 0px 4px 4px 0px #00000040;
        color: #5b5b5b;
        font-weight: 700;
        border-radius: 8px;
        line-height: 1.5rem;
        padding: 0.5rem;
        outline: none !important;
    }

    .mapinfo-popup {
        background-color: #223a5ecc;
        color: white;
        font-size: 700;
        padding: 1.5rem;
        position: absolute;
        right: 3%;
        top: 20%;
        max-height: 500px;
        overflow: auto; 
        
        p {
            line-height: 1.7rem;
        }
    }
}

@media only screen and (max-width: 1100px) and (orientation: landscape) {
    .single-project {
        .project-portal {
            height: 110vh !important;

            * {
                font-size: 0.6rem !important;
            }

            .modal-title {
                font-size: 0.9rem !important;
            }
        }

        .apt-table-wrap {
            right: -10px !important;
            width: 72% !important;
            padding: 0px 17px !important;
        }
    }
}
